// common modules
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppSizeListener } from 'react-md';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { IntercomProvider } from 'react-use-intercom';

// custom modules
import App from './App.jsx';
import configureStore from './store';
import * as ACTION from '@utilities/actions/global';
import { msalConfig, } from '@utilities/authentication.js';
import getDashboard from '@utilities/constants/dashboards'
import { EngagementMessengerProvider } from '@utilities/engagementMessenger/provider.jsx';
import { getIdToken } from '@utilities/claApi.js';

// =============================================================================
// setup authentication
// =============================================================================
// initialize MSAL client
const msal = new PublicClientApplication(msalConfig);

// enable state tracking from other tabs/windows
msal.enableAccountStorageEvents();

// default MSAL client to first account if present otherwise
if (!msal.getActiveAccount() && msal.getAllAccounts().length > 0) {
	msal.setActiveAccount(msal.getAllAccounts()[0]);
}

// setup login callback
msal.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		msal.setActiveAccount(event.payload.account);
		window.location.reload();
	}
});

// =============================================================================
// render application
// =============================================================================
const initialState = {};
const storeInstance = configureStore(initialState);
const { dashboard } = getDashboard();
storeInstance.dispatch(ACTION.setDashboard(dashboard));

ReactDOM.render(
	<React.StrictMode>
		<MsalProvider instance={msal}>
			<Provider store={storeInstance}>
				<IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
				<EngagementMessengerProvider
						url={process.env.REACT_APP_ENGAGEMENT_MESSENGER_URL}
						moduleID={process.env.REACT_APP_ENGAGEMENT_MESSENGER_MODULE_ID}
						tokenCallBack={getIdToken}>
					<AppSizeListener>
						<Router>
							<App />
						</Router>
					</AppSizeListener>
					</EngagementMessengerProvider>
				</IntercomProvider>
			</Provider>
		</MsalProvider>
	</React.StrictMode>
	,
	document.getElementById('root')
);