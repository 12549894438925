import React, { useEffect } from 'react';
import * as IMG from '@utilities/constants/images';
import usePageFramework from '@utilities/hooks/usePageFramework';
import useAdaptive from "@utilities/hooks/useAdaptive";

import './error.css';

function AccessToClientError() {
	const {
        isLaptopOrDesktop,
        isTablet,
        isMobile,
        isMobile480,
        isMobileDevice
    } = useAdaptive();

	const { selectState, history, REDUX } = usePageFramework();
	const isClient = selectState(REDUX.IS_CLIENT);
	const [pageStyle, setPageStyle] = React.useState("");

	useEffect(() => {		
		if (isTablet) {
			setPageStyle("tablet-view");
		} else if (isMobileDevice) {
			if (isMobile480 && isMobile) {
				setPageStyle("mobile-view");
			} else if (!isMobile480 && isMobile) {
				setPageStyle("mobile-view mobile-view-480");	
			} else {
				setPageStyle("");
			}
		} else {
			setPageStyle("");
		}
	}, [isTablet, isMobileDevice, isMobile, isMobile480]);

	useEffect(() => {

		// call API to trigger creation of support ticket for client error (different story)
		// what param to send to identify the client?
		// CRITICAL: ENVIRONMENT VARIABLE FOR API THAT CREATES THE SUPPORT TICKET, AUTHENTICATION SETUP ALSO.

		if (!isClient) {
			// this page should only be accessible to clients.
			history.push('/');
		}

	}, [isClient]);

	return (
		<>
			<div className="dashboardSize" style={{ minHeight: '65vh' }}>
				<div className={`error-page ${pageStyle}`}>
					<div className={`error-title ${pageStyle}`}>
						<img className="error-icon" alt="error_icon" src={IMG.ERROR_ICON} />
						Error: Your Data Cannot Be Found
					</div>
					<div className={`error-body ${pageStyle}`}>
						A support ticket has been created on your behalf, and we are working to resolve the issue. 
						We will contact you once the issue is resolved.
						For additional questions, please reach out to your local CLA tax professional.
					</div>
					<div className={`error-footer ${pageStyle}`}>
						Thank you for your patience.
					</div>
				</div>
			</div >
		</>
	);
}

export default AccessToClientError;
