// common modules
import React, { useState, useEffect } from 'react';
import { NativeSelect } from "@react-md/form";

// custom modules
import './groupSelectField.css';
import api from '@utilities/claApi';

function GroupSelectField(props) {
	const { value = [], setValue } = props;
	const [groups, setGroups] = useState([]);
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const defaultPageSize = 10;

	useEffect(() => {
		fetchGroups();
	}, [page]);

	const fetchGroups = async () => {
		try {
			const response = await api.get(`groups?limit=${defaultPageSize}&offset=${page * defaultPageSize}sort=displayName&order=desc`);
			if (response.status !== 200) {
				throw new Error(`Error fetching groups: ${response.statusText}`);
			}
			const { results, total } = await response.data;
			setGroups(prevGroups => [...prevGroups, ...results]);
			if (results.length === 0) {
				setHasMore(false);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const onMultiSelect = (event) => {
		const groupsOption = Array.from(event.target.children);
		const selectedGroups = [];
		groupsOption?.forEach(group => {
			if (group.selected) {
				selectedGroups.push(group.value)
			}
		});
		setValue(selectedGroups)
	};

	const handleScroll = (event) => {
		const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
		if (bottom && hasMore) {
			setPage(prevPage => prevPage + 1);
		}
	};

	return (
		<>
			<NativeSelect
				id="client-select-field"
				data-testid={'admin-groups-select'}
				name="select"
				label="Groups"
				value={value || []}
				multiple={true}
				onChange={onMultiSelect}
				onScroll={handleScroll}
				style={{ height: '20vh' }}
			>
				<option value="" disabled hidden />
				{groups.map((group) => (
					<option data-testid={'admin-groups-option'} key={group.id} value={group.id}>
						{`${group.displayName} (${group.name})`}
					</option>
				))}
			</NativeSelect>
		</>

	);
}

export default GroupSelectField;