// common modules
import React from 'react';

// custom modules
import RestfulTable from '@components/restfulTable/RestfulTable.js';
import * as NAV from '@utilities/constants/navigation';
import Breadcrumb from '@components/Breadcrumb.js';

function ManageUsers(props) {
    const resourceName = 'User';
    const resourceUri = 'users';
    const columns = [
        { key: 'first', text: 'First Name', create: { show: true, required: true }, edit: { show: true, required: true } },
        { key: 'middle', text: 'Middle Name', create: { show: true, required: false, }, edit: { show: true, required: false } },
        { key: 'last', text: 'Last Name', create: { show: true, required: true, }, edit: { show: true, required: true } },
        { key: 'email', text: 'Email', create: { show: true, required: true, }, edit: { show: true, required: true } },
        { key: 'userLockPermission', text: 'Lock Permission', type: 'boolean', create: { show: true }, edit: { show: true } }
    ];

    const defaultSortKey = 'displayName';
    const defaultSortOrder = 'ascending';

    const navItems = [
        { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
        { to: NAV.MANAGE_USERS, label: 'Users', current: true }
    ];

    const filterFn = (searchValue) => (item) => {
        const name = `${item.first} ${item.last}`.trim();

        return name?.toLowerCase().includes(searchValue);
    };

    return (
        <div className="pracDashboardSize">
            <Breadcrumb items={navItems} />
            <h1 style={{
                'textAlign': 'left',
                'paddingLeft': '45px',
                'color': '#2e334e'
            }}>Users</h1>
            <RestfulTable
                resourceName={resourceName}
                resourceUri={resourceUri}
                columns={columns}
                defaultSortKey={defaultSortKey}
                defaultSortOrder={defaultSortOrder}
                pageSize={5}
                filterFn={filterFn}
            />
        </div>
    );
}

export default ManageUsers;