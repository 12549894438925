import { useEffect, useState } from 'react';
import { Button, FontIcon, TextIconSpacing, Select, DropdownMenu, MenuItem } from 'react-md';
import { BOTTOM_LEFT_ANCHOR } from '@react-md/utils';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import usePageFramework from '@utilities/hooks/usePageFramework';

function EntityScrollTab(props) {
    const { entities, formName, currentIndex, changeEntity, enableDropdownSelection, addEntity, isLineSection, isFormLocked, addExistingEntity, removeEntityFromList, isParentEntityNA, isExportForm } = props;
    const { selectState } = usePageFramework();
    const formState = selectState(formName);
    const selectOptions = [{ name: 'Select', value: 'select' }];
    const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
    const currentFormKeys = [];
    let filteredEntity = [];
    const entityTabs = entities?.map((entity, index) => {
        let name = entity.name ? entity.name : `${formName} ${index + 1}`;
        let formattedName = name.toLowerCase().match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(" ").toUpperCase();
        currentFormKeys.push(entity.formNameKey);
        return (
            // eslint-disable-next-line
            <a key={`entityTab-${formName}-${index}`} className={currentIndex === index ? 'selectedTab' : 'entityTab'} onClick={() => changeEntity(index)}>{formattedName}</a>
        );
    });

    if (formState && enableDropdownSelection) {
        // eslint-disable-next-line
        filteredEntity = formState.filter(entity => !currentFormKeys.includes(entity.formNameKey)).filter(entity => entity.name);
        // eslint-disable-next-line
        filteredEntity.map((filteredEntity) => {
            selectOptions.push({ name: filteredEntity.name, value: filteredEntity.formNameKey });
        });
    }

    if (formName === 'provider') {
        selectOptions.push({
            name: 'Add New Provider',
            value: 'addNew',
            leftAddon: <TextIconSpacing className='addChildCareIcon' icon={<FontIcon>add</FontIcon>}></TextIconSpacing>,
            className: 'addChildCare'
        });
    }
    else if (formName === 'vehicle') {
        selectOptions.push({
            name: 'Add New Vehicle',
            value: 'addNew',
            leftAddon: <TextIconSpacing className='addVehicleIcon' icon={<FontIcon>add</FontIcon>}></TextIconSpacing>,
            className: 'addVehicle'
        });
    }

    useEffect(() => {
        if (
            selectOptions?.some(option => !['select', 'addNew'].includes(option.value))
        ) {
            setIsAddButtonClicked(true);
        }
    }, [selectOptions]);


    const onSelectChange = (value) => {
        setIsAddButtonClicked(false);
        if (value === 'addNew') {
            addEntity();
        } else {
            addExistingEntity(value);
        }
    };

    const onAddButtonClicked = () => {
        if (!enableDropdownSelection) {
            addEntity();
        } else {
            setIsAddButtonClicked(true);
        }
    };

    const isEmpty = filteredEntity.length === 0;

    const handleClickAddEntity = () => {
        if (isFormLocked) return;
        else if (isEmpty) {
            addEntity();
        }
        else {
            onAddButtonClicked();
        }
    }

    const menuItems = [
        <MenuItem
            onClick={() => removeEntityFromList(entities[currentIndex]?.formNameKey)}>
            {`${formName === 'vehicle' ? 'Remove Vehicle' : 'Remove Provider'}`}
        </MenuItem>
    ];

    return (
        <>
            {isExportForm ? null :
                <div className={enableDropdownSelection ? 'scrollTabGroup' : isLineSection ? 'lineSectiontabsContainer' : 'tabsContainer'}>
                    <div className={isLineSection ? 'scrollMenuContainerLineSection' : 'scrollMenuContainer'}>
                        <ScrollMenu
                            arrowLeft={<FontIcon className='menuArrow'>chevron_left</FontIcon>}
                            arrowRight={<FontIcon className='menuArrow'>chevron_right</FontIcon>}
                            clickWhenDrag={false}
                            data={entityTabs}
                            data-testid="entity-scroll-menu"
                            dragging={true}
                            hideArrows={true}
                            hideSingleArrow={true}
                            scrollToSelected={true}
                            selected={`entityTab-${formName}-${currentIndex}`}
                            transition={0.3}
                            translate={0}
                            alignCenter={false}
                        />
                    </div>
                    <div className='scrollMenuBtnContainer'>
                        {!isAddButtonClicked && formName !== 'vehicle' && formName !== 'provider' ?
                            <Button
                                className='addEntityIcon'
                                data-testid='Add-New-EntityIcon'
                                buttonType='icon'
                                onClick={isFormLocked ? () => { } : () => onAddButtonClicked()}
                                disabled={isFormLocked}
                            >
                                <TextIconSpacing className='addNewText' icon={<FontIcon>add</FontIcon>}></TextIconSpacing>
                                {`Add ${formName === "vehicle" ? 'Vehicle' : 'New'}`}
                            </Button> :

                            !isAddButtonClicked && formName === 'vehicle' ?
                                <Button
                                    className='addEntityIcon'
                                    data-testid='Add-NewVechile-EntityIcon'
                                    buttonType='icon'
                                    onClick={handleClickAddEntity}
                                    disabled={isFormLocked || isParentEntityNA}
                                >
                                    <TextIconSpacing className='addNewText' icon={<FontIcon>add</FontIcon>}></TextIconSpacing>
                                    {`Add New Vehicle`}
                                </Button> :

                                !isAddButtonClicked && formName === 'provider' ?
                                    <Button
                                        className='addEntityIcon'
                                        data-testid='Add-NewVechile-EntityIcon'
                                        buttonType='icon'
                                        onClick={handleClickAddEntity}
                                        disabled={isFormLocked}
                                    >
                                        <TextIconSpacing className='addNewText' icon={<FontIcon>add</FontIcon>}></TextIconSpacing>
                                        {`Add New Provider`}
                                    </Button> :

                                    <div style={{ width: '150px' }}>
                                        <Select
                                            options={selectOptions}
                                            value={'select'}
                                            label={''}
                                            id={'select-scroll-tab'}
                                            data-testid='select-scroll-tab'
                                            labelKey={'name'}
                                            valueKey={'value'}
                                            onChange={(event) => onSelectChange(event)}
                                            style={{ backgroundColor: '#ffffff', height: '30px' }}
                                        />
                                    </div>

                        }
                        <DropdownMenu
                            id={`${formName}-${currentIndex}`}
                            className='dropDownMenu'
                            data-testid="entity-options-menu"
                            style={entities?.length <= 1 || !enableDropdownSelection ? { visibility: 'hidden' } : null}
                            buttonType='icon'
                            aria-label='Entity Options...'
                            anchor={BOTTOM_LEFT_ANCHOR}
                            disabled={isFormLocked || isParentEntityNA || entities?.length <= 1 || !enableDropdownSelection}
                            buttonChildren={<MoreVertSVGIcon className='whiteEllipsisEntityIcon' />}
                        >
                            <span data-testid="remove-entity-option">{menuItems}</span>
                        </DropdownMenu>
                    </div>
                </div>}
        </>
    );
}

export default EntityScrollTab;
