import { useEffect, useState } from 'react';
import { Switch } from 'react-md';
import './CustomSwitch.scss';

const CustomSwitch = (props) => {
    const { onChange, checked, defaultChecked, disabled } = props;
    const [isChecked, setIsChecked] = useState(!!defaultChecked || !!checked);
    const [className, setClassName] = useState(
        `custom-switcher${disabled ? ` custom-switcher--disabled` : ''}${isChecked ? ` custom-switcher--checked` : ''}`
    );

    useEffect(() => {
        if ('className' in props) {
            setClassName(value => {
                value += ` ${props.className}`;
                return value;
            });
        }
    
        if (disabled) {
            setClassName(value => {
                value += ` custom-switcher--disabled`;
                return value;
            });
        }
    }, [props.disabled, props.className]);

    useEffect(() => {
        setClassName(value => {
            if (isChecked && !value.includes(` custom-switcher--checked`)) {
                value += ` custom-switcher--checked`;
            }
            if (!isChecked) {
                value = value.replace(' custom-switcher--checked', '');
            }
            return value;
        });
    }, [isChecked])

    const handleChecked = (event) => {
        setIsChecked(event.target.checked);
        onChange(event);
    };

    return (
        <Switch
            {...props}
            className={className}
            onChange={handleChecked}
        />
    )
};

export default CustomSwitch;