// logos
import CLA_WHITE_LOGO from '@assets/claLogoHeaderWhite.png';
import CLA_NAVY_LOGO from '@assets/navy-copy-sv.svg';

// icons
import SHOW_ICON from '@assets/eye.svg';
import FILTER_ICON from '@assets/filter.svg';
import MORE_ICON from '@assets/more.svg';
import NOTES_ICON from '@assets/notes.svg';
import SIL_OPPORTUNITIES_ICON from '@assets/silOpportunities.png';
import WARNING_ICON from '@assets/warningIcon.png'
import WHITE_EXPANDED_ICON from '@assets/whiteExpanded.png';
import DETAILS_ICON from '@assets/detailsIcon.svg';
import MARKNA_ICON from '@assets/markNaIcon.svg';
import JUMPTO_ICON from '@assets/jumpToIcon.svg';
import VIEWLESS_ICON from '@assets/viewLessIcon.svg';
import DOWNLOAD_ALL_ICON from '@assets/download-all.svg';
import PROFILE_ICON from '@assets/claAvatar.svg';
import ARROW_RIGHT from '@assets/icon-arrow-right.svg';
import ARROW_LEFT from '@assets/icon-arrow-left.svg';
import ARROW_RIGHT_BLUE from '@assets/icon-arrow-right-blue.svg';
import ARROW_LEFT_BLUE from '@assets/icon-arrow-left-blue.svg';
import ERROR_ICON from '@assets/error-1.svg';

//dashboard card @assets
import ABOUT_YOUR_YEAR from '@assets/about-your-year.svg';
import CONTRIBUTED_TO_A_RETIREMENT_ACCOUNT from
  '@assets/contributed-to-a-retirement-account-dashboard.svg';
import DEPENDENTS from '@assets/dependents.svg';
import EARNED_WAGES_W2 from '@assets/earned-wages-w-2-dashboard.svg';
import HAD_ANOTHER_SOURCE_OF_INCOME from
  '@assets/had-another-source-of-income-dashboard.svg';
import HAD_BUSINESS_INCOME from '@assets/had-business-income-dashboard.svg';
import MADE_AN_ENERGY_EFFICIENT_PURCHASE from
  '@assets/made-an-energy-efficient-purchase-dashboard.svg';
import OWNED_A_FARM_SCHEDULE_F from
  '@assets/owned-a-farm-schedule-f-dashboard.svg';
import OWNED_RENTAL_PROPERTY_SCHEDULE_E from
  '@assets/owned-rental-property-schedule-e-dashboard.svg';
import PAID_FOR_CHILD_DEPENDENT_CARE from
  '@assets/paid-for-child-dependent-care-dashboard.svg';
import PAID_HIGHER_EDUCATION from
  '@assets/paid-higher-education-dashboard.svg';
import PAID_HOUSEHOLD_EMPLOYEES from
  '@assets/paid-household-employees-dashboard.svg';
import PAID_MEDICAL_EXPENSES from
  '@assets/paid-medical-expenses-copy-dashboard.svg';
import PAID_MORTGAGE_OR_OTHER_INTERESTS from
  '@assets/paid-mortage-or-other-interests-dashboard.svg';
import PAID_PROPERTY_TAX from '@assets/paid-property-tax-dashboard.svg';
import REQUIRED_INFORMATION from '@assets/required-information.svg';
import RECEIVED_A_K1 from '@assets/received-a-k-1-dashboard.svg';
import RECEIVED_INVESTMENT_INCOME from
  '@assets/received-investment-income-dashboard.svg';
import RECEIVED_RETIREMENT_INCOME from
  '@assets/received-retirement-income-dashboard.svg';
import SUPPORTED_CHARITABLE_ORGANIZATIONS from
  '@assets/supported-charitable-organizations-dashboard.svg';
import WORKED_OUTSIDE_THE_US from
  '@assets/worked-outside-the-us-dashboard.svg';
import REFUND_PAYMENT_OPTIONS from '@assets/refund-payment-options.svg';
import TAX_PAYMENTS from '@assets/tax-payments-dashboard.svg';
import OTHER_DEDUCTIONS from '@assets/group-41-dashboard.svg';
import FOREIGN_INCOME_DASHBOARD from '@assets/Foreign_Income_Icon.svg';
import CHECKLIST from '@assets/checklist.svg';
import DASHBOARD_TAX_RETURN from '@assets/dashboard-tax-return-icon.svg';
import TAX_RETURN_DOWNLOAD_ACTIVE from '@assets/tax_return_download_active.svg';
import TAX_RETURN_DOWNLOAD_INACTIVE from '@assets/tax_return_download_inactive.svg';

//entry experience unselected @assets
import ENTRY_EARNED_WAGES_W2_INACTIVE from '@assets/earned-wages-w-2.svg';
import ENTRY_RECEIVED_INVESTMENT_INCOME_INACTIVE from '@assets/received-investment-income.svg';
import ENTRY_RECEIVED_A_K1_INACTIVE from '@assets/received-a-k-1.svg';
import ENTRY_HAD_ANOTHER_SOURCE_OF_INCOME_INACTIVE from '@assets/had-another-source-of-income.svg';
import ENTRY_RECEIVED_RETIREMENT_INCOME_INACTIVE from '@assets/received-retirement-income.svg';
import ENTRY_HAD_BUSINESS_INCOME_INACTIVE from '@assets/had-business-income.svg';
import ENTRY_OWNED_RENTAL_PROPERTY_SCHEDULE_E_INACTIVE from '@assets/owned-rental-property-schedule-e.svg';
import ENTRY_OWNED_A_FARM_SCHEDULE_F_INACTIVE from '@assets/owned-a-farm-schedule-f.svg';
import ENTRY_EARNED_FOREIGN_INCOME from '@assets/Foreign_Income_Entry.svg';
import ENTRY_HELD_FOREIGN_ASSETS from '@assets/worked-outside-the-us.svg';
import ENTRY_GETTING_STARTED_ASSETS from '@assets/getting-started-assets.svg';

import ENTRY_PAID_MEDICAL_EXPENSES_INACTIVE from '@assets/paid-medical-expenses-copy.svg';
import ENTRY_PAID_PROPERTY_TAX_INACTIVE from '@assets/paid-property-tax.svg';
import ENTRY_PAID_MORTGAGE_OR_OTHER_INTERESTS_INACTIVE from '@assets/paid-mortage-or-other-interests.svg';
import ENTRY_CONTRIBUTED_TO_A_RETIREMENT_ACCOUNT_INACTIVE from '@assets/contributed-to-a-retirement-account.svg';
import ENTRY_SUPPORTED_CHARITABLE_ORGANIZATIONS_INACTIVE from '@assets/supported-charitable-organizations.svg';
import ENTRY_PAID_FOR_CHILD_DEPENDENT_CARE_INACTIVE from '@assets/paid-for-child-dependent-care.svg';
import ENTRY_PAID_HIGHER_EDUCATION_INACTIVE from '@assets/paid-higher-education.svg';
import ENTRY_MADE_AN_ENERGY_EFFICIENT_PURCHASE_INACTIVE from '@assets/made-an-energy-efficient-purchase.svg';
import ENTRY_PAID_HOUSEHOLD_EMPLOYEES_INACTIVE from '@assets/paid-household-employees.svg';
import ENTRY_OTHER_DEDUCTIONS_INACTIVE from '@assets/group-41.svg';

import ENTRY_INVESTMENT_PLANNING_INACTIVE from '@assets/investment-planning.svg';
import ENTRY_TAX_AND_FINANCIAL_PLANNING_INACTIVE from '@assets/tax-and-financial-planning.svg';
import ENTRY_INSURANCE_PLANNING_INACTIVE from '@assets/insurance-planning.svg';
import ENTRY_OWNER_TRANSITION_SERVICES_INACTIVE from '@assets/owner-transition-services.svg';
import ENTRY_EXPERIENCE_TOP_LAYER from '@assets/entryExperienceTopLayer.svg';
import ENTRY_EXPERIENCE_MIDDLE_LAYER from '@assets/entryExperienceMiddleLayer.svg';
import ENTRY_EXPERIENCE_BOTTOM_LAYER from '@assets/entryExperienceBottomLayer.svg';
import CLA_WELCOME_BG from '@assets/cla_welcome.svg';
import ENTRY_EXPERIENCE_MOBILE_BACKGROUND from '@assets/entryExperienceMobileBackground.svg'
import ENTRY_EXPERIENCE_MOBILE_BACKGROUND_RECTANGLE from '@assets/entryExperienceMobileBackgroundRectangle.svg'
import ENTRY_EXPERIENCE_MOBILE_FOOTER from '@assets/entryExperienceMobileFooter.svg'
import CLA_LOGO_MOBILE_FOOTER from '@assets/claLogoMobileFooter.svg'
import CLA_LOGO_MOBILE from '@assets/claLogoMobileTermsAndConditionsHeader.svg'
import CLA_LOGO_MOBILE_WELCOME_INTRO from '@assets/claLogoMobileWelcomeIntro.svg'
import WELCOME_TOP_LAYER_TABLET from '@assets/welcomeScreenTopLayer.svg';

import DELETE_ICON from '@assets/delete.svg';

import COMPLETE_ICON_ACTIVE from '@assets/completeIconActive.svg';
import COMPLETE_ICON_NORMAL from '@assets/completeIconNormal.svg';
import NA_ICON_ACTIVE from '@assets/naIconActive.svg';
import NA_ICON_NORMAL from '@assets/naIconNormal.svg';
import NOTES_HEADER from '@assets/notesHeaderNormal.svg';

//form exportRenderer icons
import naIconSecondary from '@assets/lineNaNormal.svg';
import naIconCompleted from '@assets/lineNaActive.svg';
import uploadIconPrimary from '@assets/uploadCtaNormal.svg';
import removeIconNormal from '@assets/lineRemoveNormal.svg';
import warningIcon from '@assets/warningIcon.svg';
import viewUploadsIcon from '@assets/uploadFilesActive.svg';
import hideUploadsIcon from '@assets/uploadHideFilesActive.svg';
import lineClearEvent from '@assets/lineClearEvent.svg';

// bar codes for organizer export
import barcodeAboutYou from '@assets/orgExport/AboutYou.png';
import barcodeAboutYourYear from '@assets/orgExport/AboutYourYear.png';
import barcodeAdditionalAdjustmentsToIncome from '@assets/orgExport/AdditionalAdjustmentsToIncome.png';
import barcodeAdjustmentsToIncome from '@assets/orgExport/AdjustmentsToIncome.png';
import barcodeCharitableContributions from '@assets/orgExport/CharitableContributions.png';
import barcodeChildDependentCare from '@assets/orgExport/ChildDependentCare.png';
import barcodeDependents from '@assets/orgExport/Dependents.png';
import barcodeForeignAssets from '@assets/orgExport/ForeignAssets.png';
import barcodeForeignIncome from '@assets/orgExport/ForeignIncome.png';
import barcodeHigherEducation from '@assets/orgExport/HigherEducation.png';
import barcodeHouseholdEmploymentPayment from '@assets/orgExport/HouseholdEmploymentPayment.png';
import barcodeOtherDeductions from '@assets/orgExport/OtherDeductions.png';
import barcodeInvestmentIncomeBrokerageStatement from '@assets/orgExport/InvestmentIncomeBrokerageStatement.png';
import barcodeInvestmentIncomeDividendIncome from '@assets/orgExport/InvestmentIncomeDividendIncome.png';
import barcodeInvestmentIncomeInterestIncome from '@assets/orgExport/InvestmentIncomeInterestIncome.png';
import barcodeInvestmentIncomeOtherInvestmentIncome from '@assets/orgExport/InvestmentIncomeOtherInvestmentIncome.png';
import barcodeMedicalExpenses from '@assets/orgExport/MedicalExpenses.png';
import barcodeMortgageorOtherInterest from '@assets/orgExport/MortgageorOtherInterest.png';
import barcodeOtherSourcesOfIncome from '@assets/orgExport/OtherSourcesOfIncome.png';
import barcodePassthroughIncome from '@assets/orgExport/PassthroughIncome.png';
import barcodePropertyTaxes from '@assets/orgExport/PropertyTaxes.png';
import barcodeRefundPaymentPreferences from '@assets/orgExport/RefundPaymentPreferences.png';
import barcodeRetirementIncome from '@assets/orgExport/RetirementIncome.png';
import barcodeRetirementPlanDistributions from '@assets/orgExport/RetirementPlanDistributions.png';
import barcodeSocialSecurityBenefits from '@assets/orgExport/SocialSecurityBenefits.png';
import barcodeStudentLoanInterestDeduction from '@assets/orgExport/StudentLoanInterestDeduction.png';
import barcodeTaxPayments from '@assets/orgExport/TaxPayments.png';
import barcodeWagesW2 from '@assets/orgExport/WagesW2.png';
import barcodeRetirementContributions from '@assets/orgExport/RetirementContributions.png';

// org export button download icon
import ORG_EXPORT_DOWNLOAD from '@assets/orgExport/SummaryPDF_Download_Icon.png';

// add the 14 Schedule bar codes here
// Sched C
import barcodeVehicleExpensesScheduleC from '@assets/orgExport/VehicleExpensesScheduleC.png';
import barcodeBusinessUseofHomeScheduleC from '@assets/orgExport/BusinessUseofHomeScheduleC.png';
import barcodeBusinessInformation from '@assets/orgExport/BusinessInformation.png';
import barcodeBusinessIncome from '@assets/orgExport/BusinessIncome.png';
import barcodeBusinessExpenses from '@assets/orgExport/BusinessExpenses.png';

// Sched E
import barcordeRentalPropertyInformation from '@assets/orgExport/RentalPropertyInformation.png';
import barcodeRentalPropertyIncome from '@assets/orgExport/RentalPropertyIncome.png';
import barcodeRentalPropertyExpenses from '@assets/orgExport/RentalPropertyExpenses.png';
import barcodeVehicleExpensesScheduleE from '@assets/orgExport/VehicleExpensesScheduleE.png';

// Sched F
import barcodeVehicleExpensesScheduleF from '@assets/orgExport/VehicleExpensesScheduleF.png';
import barcodeFarmInformation from '@assets/orgExport/FarmInformation.png';
import barcodeFarmIncome from '@assets/orgExport/FarmIncome.png';
import barcodeFarmExpenses from '@assets/orgExport/FarmExpenses.png';
import barcodeBusinessUseofHomeScheduleF from '@assets/orgExport/BusinessUseofHomeScheduleF.png';

import * as REDUX from '@utilities/constants/redux';

export const getFormBarcode = (formName = '', defaultFormName = '', barcodeName = '') => {
  const FARM_EQUIPMENT = 'Farm Equipment and Property (See Next Section for Farm Vehicles)';
  const barcodes = {
    // All 25 forms from Index page
    [REDUX.ABOUT_YOU]: barcodeAboutYou,
    [REDUX.DEPENDENTS]: barcodeDependents,
    [REDUX.ABOUT_YOUR_YEAR]: barcodeAboutYourYear,
    [REDUX.REFUND_TAX_PAYMENT]: barcodeRefundPaymentPreferences,
    [REDUX.TAX_PAYMENTS]: barcodeTaxPayments,
    [REDUX.FOREIGN_ASSETS]: barcodeForeignAssets,
    [REDUX.WAGES]: barcodeWagesW2,
    [REDUX.INVESTMENT_INCOME]: barcodeInvestmentIncomeInterestIncome,
    [REDUX.RETIREMENT_INCOME]: barcodeRetirementIncome,
    // Schedule C
    [REDUX.BUSINESS_INCOME]: barcodeBusinessIncome,
    // Schedule E
    [REDUX.RENTAL_INCOME]: barcordeRentalPropertyInformation,
    // Sched F
    [REDUX.FARM_INCOME]: barcodeFarmIncome,
    [FARM_EQUIPMENT]: barcodeFarmIncome,
    [REDUX.PASSTHRU_INCOME]: barcodePassthroughIncome,
    [REDUX.MISC_INCOME]: barcodeOtherSourcesOfIncome,
    [REDUX.FOREIGN_INCOME]: barcodeForeignIncome,
    [REDUX.RETIREMENT_CONTRIBUTIONS]: barcodeRetirementContributions,
    [REDUX.MEDICAL_EXPENSES]: barcodeMedicalExpenses,
    [REDUX.PROPERTY_STATE_TAXES]: barcodePropertyTaxes,
    [REDUX.MORTGAGE_INVESTMENT]: barcodeMortgageorOtherInterest,
    [REDUX.CONTRIBUTIONS]: barcodeCharitableContributions,
    [REDUX.DEPENDENT_CARE]: barcodeChildDependentCare,
    [REDUX.HIGHER_EDUCATION]: barcodeHigherEducation,
    [REDUX.HOUSEHOLD_EMPLOYMENT]: barcodeHouseholdEmploymentPayment,
    [REDUX.OTHER_DEDUCTIONS]: barcodeOtherDeductions,

    // Other secondary forms bar code images
    barcodeAdjustmentsToIncome,
    barcodeAdditionalAdjustmentsToIncome,
    barcodeRetirementPlanDistributions,
    barcodeSocialSecurityBenefits,
    barcodeStudentLoanInterestDeduction,
    barcodeInvestmentIncomeBrokerageStatement,
    barcodeInvestmentIncomeDividendIncome,
    barcodeInvestmentIncomeOtherInvestmentIncome,
    // Sched C
    barcodeVehicleExpensesScheduleC,
    barcodeBusinessUseofHomeScheduleC,
    barcodeBusinessExpenses,
    barcodeBusinessInformation,
    // Sched E
    barcodeRentalPropertyIncome,
    barcodeRentalPropertyExpenses,
    barcodeVehicleExpensesScheduleE,
    // Sched F
    barcodeFarmInformation,
    barcodeFarmExpenses,
    barcodeBusinessUseofHomeScheduleF,
    barcodeVehicleExpensesScheduleF
  }

  let modifiedFormName = formName.split('-');
  modifiedFormName = modifiedFormName.length > 1 ? modifiedFormName[0] : formName;

  if (formName) {
    return barcodes[modifiedFormName];
  }

  else if (defaultFormName) {
    return barcodes[defaultFormName];
  }
  else if (barcodeName) {
    return barcodes[barcodeName];
  }
}


export {
  CLA_WHITE_LOGO,
  CLA_NAVY_LOGO,
  SHOW_ICON,
  FILTER_ICON,
  MORE_ICON,
  NOTES_ICON,
  SIL_OPPORTUNITIES_ICON,
  WARNING_ICON,
  WHITE_EXPANDED_ICON,
  DETAILS_ICON,
  MARKNA_ICON,
  JUMPTO_ICON,
  VIEWLESS_ICON,
  DOWNLOAD_ALL_ICON,
  ABOUT_YOUR_YEAR,
  DEPENDENTS,
  CONTRIBUTED_TO_A_RETIREMENT_ACCOUNT,
  EARNED_WAGES_W2,
  HAD_ANOTHER_SOURCE_OF_INCOME,
  HAD_BUSINESS_INCOME,
  MADE_AN_ENERGY_EFFICIENT_PURCHASE,
  OWNED_A_FARM_SCHEDULE_F,
  OWNED_RENTAL_PROPERTY_SCHEDULE_E,
  PAID_FOR_CHILD_DEPENDENT_CARE,
  PAID_HIGHER_EDUCATION,
  PAID_HOUSEHOLD_EMPLOYEES,
  PAID_MEDICAL_EXPENSES,
  PAID_MORTGAGE_OR_OTHER_INTERESTS,
  PAID_PROPERTY_TAX,
  REQUIRED_INFORMATION,
  RECEIVED_A_K1,
  RECEIVED_INVESTMENT_INCOME,
  RECEIVED_RETIREMENT_INCOME,
  SUPPORTED_CHARITABLE_ORGANIZATIONS,
  WORKED_OUTSIDE_THE_US,
  REFUND_PAYMENT_OPTIONS,
  TAX_PAYMENTS,
  OTHER_DEDUCTIONS,
  FOREIGN_INCOME_DASHBOARD,
  ENTRY_EARNED_WAGES_W2_INACTIVE,
  ENTRY_RECEIVED_INVESTMENT_INCOME_INACTIVE,
  ENTRY_RECEIVED_A_K1_INACTIVE,
  ENTRY_HAD_ANOTHER_SOURCE_OF_INCOME_INACTIVE,
  ENTRY_RECEIVED_RETIREMENT_INCOME_INACTIVE,
  ENTRY_HAD_BUSINESS_INCOME_INACTIVE,
  ENTRY_OWNED_RENTAL_PROPERTY_SCHEDULE_E_INACTIVE,
  ENTRY_OWNED_A_FARM_SCHEDULE_F_INACTIVE,
  ENTRY_EARNED_FOREIGN_INCOME,
  ENTRY_HELD_FOREIGN_ASSETS,
  ENTRY_GETTING_STARTED_ASSETS,
  ENTRY_PAID_MEDICAL_EXPENSES_INACTIVE,
  ENTRY_PAID_PROPERTY_TAX_INACTIVE,
  ENTRY_PAID_MORTGAGE_OR_OTHER_INTERESTS_INACTIVE,
  ENTRY_CONTRIBUTED_TO_A_RETIREMENT_ACCOUNT_INACTIVE,
  ENTRY_SUPPORTED_CHARITABLE_ORGANIZATIONS_INACTIVE,
  ENTRY_PAID_FOR_CHILD_DEPENDENT_CARE_INACTIVE,
  ENTRY_PAID_HIGHER_EDUCATION_INACTIVE,
  ENTRY_MADE_AN_ENERGY_EFFICIENT_PURCHASE_INACTIVE,
  ENTRY_PAID_HOUSEHOLD_EMPLOYEES_INACTIVE,
  ENTRY_OTHER_DEDUCTIONS_INACTIVE,
  ENTRY_INVESTMENT_PLANNING_INACTIVE,
  ENTRY_TAX_AND_FINANCIAL_PLANNING_INACTIVE,
  ENTRY_INSURANCE_PLANNING_INACTIVE,
  ENTRY_OWNER_TRANSITION_SERVICES_INACTIVE,
  ENTRY_EXPERIENCE_BOTTOM_LAYER,
  ENTRY_EXPERIENCE_MIDDLE_LAYER,
  ENTRY_EXPERIENCE_TOP_LAYER,
  CLA_WELCOME_BG,
  ENTRY_EXPERIENCE_MOBILE_BACKGROUND,
  ENTRY_EXPERIENCE_MOBILE_BACKGROUND_RECTANGLE,
  ENTRY_EXPERIENCE_MOBILE_FOOTER,
  CLA_LOGO_MOBILE_FOOTER,
  CLA_LOGO_MOBILE,
  CLA_LOGO_MOBILE_WELCOME_INTRO,
  WELCOME_TOP_LAYER_TABLET,
  DELETE_ICON,
  COMPLETE_ICON_ACTIVE,
  COMPLETE_ICON_NORMAL,
  NA_ICON_ACTIVE,
  NA_ICON_NORMAL,
  NOTES_HEADER,
  PROFILE_ICON,
  naIconSecondary,
  naIconCompleted,
  uploadIconPrimary,
  removeIconNormal,
  warningIcon,
  viewUploadsIcon,
  hideUploadsIcon,
  lineClearEvent,
  barcodeAboutYou,
  barcodeAboutYourYear,
  barcodeAdditionalAdjustmentsToIncome,
  barcodeAdjustmentsToIncome,
  barcodeCharitableContributions,
  barcodeChildDependentCare,
  barcodeDependents,
  barcodeForeignAssets,
  barcodeForeignIncome,
  barcodeHigherEducation,
  barcodeHouseholdEmploymentPayment,
  barcodeOtherDeductions,
  barcodeInvestmentIncomeBrokerageStatement,
  barcodeInvestmentIncomeDividendIncome,
  barcodeInvestmentIncomeInterestIncome,
  barcodeInvestmentIncomeOtherInvestmentIncome,
  barcodeMedicalExpenses,
  barcodeMortgageorOtherInterest,
  barcodeOtherSourcesOfIncome,
  barcodePassthroughIncome,
  barcodePropertyTaxes,
  barcodeRefundPaymentPreferences,
  barcodeRetirementIncome,
  barcodeRetirementPlanDistributions,
  barcodeSocialSecurityBenefits,
  barcodeStudentLoanInterestDeduction,
  barcodeTaxPayments,
  barcodeWagesW2,
  barcordeRentalPropertyInformation,
  barcodeVehicleExpensesScheduleF,
  barcodeFarmInformation,
  barcodeFarmIncome,
  barcodeFarmExpenses,
  barcodeBusinessUseofHomeScheduleF,
  barcodeVehicleExpensesScheduleE,
  barcodeRentalPropertyIncome,
  barcodeRentalPropertyExpenses,
  barcodeVehicleExpensesScheduleC,
  barcodeBusinessUseofHomeScheduleC,
  barcodeBusinessInformation,
  barcodeBusinessIncome,
  barcodeBusinessExpenses,
  ORG_EXPORT_DOWNLOAD,
  ARROW_RIGHT,
  ARROW_LEFT,
  ARROW_RIGHT_BLUE,
  ARROW_LEFT_BLUE,
  CHECKLIST,
  DASHBOARD_TAX_RETURN,
  TAX_RETURN_DOWNLOAD_ACTIVE,
  TAX_RETURN_DOWNLOAD_INACTIVE,
  ERROR_ICON
};