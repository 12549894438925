import * as CARDSTATUS from '@utilities/constants/cardStatus';
import { getClientDetails } from '../organizerExport/organizerRenderer/components/utils/getClientDetails';
import usePageFramework from '@utilities/hooks/usePageFramework';
import NotesIndex from './organizerRenderer/components/notes';
import { getCardProp } from '@utilities/helpers/getCardProp';
import getDashboard from '@utilities/constants/dashboards';
import CLA_NAVY_LOGO from '@assets/navy-copy-sv.svg';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

function ExportIndex(props) {
    const { REDUX, selectState } = usePageFramework();
    const year = selectState(REDUX.YEAR) || 2022;
    const clientDetails = getClientDetails();
    const [cards, setCards] = useState([]);
    const [client, setClient] = useState(null);

	const getSectionName = (obj) => {
		return obj.worksheet?.[0]?.split('\\').pop() || null;
	};

    // Should all cards show or just the filled ones?
    // const filteredCards = props.index.filter(card => card.statusId !== CARDSTATUS.NOT_STARTED || card.isRequired);
    const setFormStatus = (card) => {
        const sectionName = getSectionName(card);

        // Check if card should be in Required or Recommended based on hasPriorData and worksheet
        if (sectionName && ['Sch C - Business', 'Sch E, p 1 - Rent and Royalty', 'Sch F / 4835 - Farm'].includes(sectionName)) {
            if (!card.isRequired && card.hasPriorData) {
                card.isRequired = true;
            }
        }

        const isRecommended = !card.isRequired && (card.hasPriorData || card.isRecommended);
        card.statusId = card.statusId === CARDSTATUS.REQUIRED ? CARDSTATUS.NOT_STARTED : card.statusId;
        const cardRequiredText = card.isRequired ? 'Required' : (isRecommended ? 'Recommended' : 'Optional');
        return `${cardRequiredText} - ${CARDSTATUS.STATUS_TITLE[card.statusId]}`;
    };

    const { dashboardCards } = getDashboard(year);
    
    useEffect(() => {
        if (dashboardCards && !cards.length) {
            setCards(cloneDeep(dashboardCards));
        }
    }, []);

    useEffect(() => {
        if (clientDetails?.clientName && clientDetails?.clientNumber && !client) {
            setClient(clientDetails);
        }
    }, [clientDetails]);

    return (
        <>
            <div className="grid-container">
                <div className="grid-child" style={{ width: 139, height: 31, textAlign: 'left' }}>
                    <img width="25px" height="25px" src={CLA_NAVY_LOGO} />
                </div>
                <div className="grid-child yearIndex">
                    <h2>{`${year} Index`}</h2>
                </div>
                <div className="grid-child clientDetailsContainer">
                    <span>{client?.clientName}</span>&nbsp;
                    <span>{client?.clientNumber}</span>
                </div>
            </div>
            <div className="tableHeader">
                <h4>Card</h4>
                <h4>Status</h4>
            </div>
            <hr />
            <div className='orgTable'>
                {props.index?.map((card, index) => (
                    <div key={index}>
                        <div>{getCardProp(card.formName, 'title', cards)}</div>
                        <div>{setFormStatus(card)}</div>
                    </div>
                ))}
            </div>
             <NotesIndex forms={props.index} clientName={client?.clientName}/>
        </>
    )
}

export default ExportIndex;