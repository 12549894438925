import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogHeader, DialogContent, DialogFooter,
} from "@react-md/dialog";
import { Button, Grid, GridCell, Divider } from 'react-md';

import { CLOSE, ESIGNATURE_STATUS_DETAILS } from '@utilities/constants/strings';

import { getUserEsignatureStatus } from '@utilities/services/taxReturnService';

import './style.css'
import TaxReturnStatusGridText from './taxReturnStatusText';

function EsignatureStatusDetailsDialog(props) {
    const { organizerId, disableEsignatureStatusDialog } = props;
    const [userList, setUserList] = useState();
    const [error, setError] = useState();

    const getUserList = async () => {
        const userData = [];
        try {

            const users = await getUserEsignatureStatus(organizerId)
            if (users) {           
                userData.push(...users.map(user => {
                    return {
                        ...user,
                        name: user.displayName
                    }
                }));
                //Filter out user (spouse) with no email address.
                //We are allowing spouse with no data in-placed but with email address.
                const filtered = userData.filter((user) => user.email?.trim());
                setUserList(filtered);
                setError(null);
            }
        } catch (error) {
            console.error(`Error getting eSignature status:`, error?.message);
            setError(`Error getting eSignature status`);
        }
    }

    const renderStatusGridCell = (user, index, colSpan) => {
        return (
            <TaxReturnStatusGridText
                taxReturnStatusValue={user.taxReturnStatus}
                propertyName={'taxReturnStatusValue'}
                index={index}
                colSpan={colSpan}
            />
        )
    }

    const renderRowGridCell = (user, propertyName, index, colSpan) => {
        return (
            <GridCell
                colSpan={colSpan}
                className={`email-dialog-font esignature-status-cell`}
                data-testid={`esignature-status-${propertyName}-${index}`}
            >
                {user[propertyName]}
            </GridCell>
        )
    }

    const renderHeaderGridCell = (colSpan, cellText, customStyle) => {
        return (
            <GridCell
                colSpan={colSpan}
                className={`esignature-status-header`}
                style={customStyle}
            >
                {cellText}
            </GridCell>
        );
    }

    useEffect(() => {
        getUserList();
    }, [organizerId]);

    return (
        <Dialog
            id="esignature-status-dialog"
            role="alertdialog"
            visible={true}
            onRequestClose={() => { }}
            className={'esignature-status-dialog'}
            aria-labelledby="esignature-status-dialog-title"
            data-testid="esignature-status-dialog-title">
            <DialogHeader className={'dialog-header'}>
                <div>
                    <p className={'esignature-dialog-header'}>{ESIGNATURE_STATUS_DETAILS}</p>
                    <p className={'esignature-dialog-sub-header'}>Note: Clients in 'Sent' status are automatically emailed eSignature reminders every 2 days.</p>
                </div>
            </DialogHeader>
            <DialogContent className={'dialog-body'}>
                <Grid className='esignatureStatusHeaderContainer'>
                    {renderHeaderGridCell(3, 'Client Name')}
                    {renderHeaderGridCell(4, 'Email Address')}
                    {renderHeaderGridCell(2, 'Role')}
                    {renderHeaderGridCell(3, 'Status')}
                </Grid>
                {error && <div className={'loading-div'}> {error} </div>}
                {
                    userList
                        ? userList.map((user, index) => (
                            <Grid key={`user-row-${index}`} className='esignatureStatusGridContainer'>
                                {renderRowGridCell(user, 'name', index, 3)}
                                {renderRowGridCell(user, 'email', index, 4)}
                                {renderRowGridCell(user, 'role', index, 2)}
                                {renderStatusGridCell(user, index, 3)}
                                <GridCell colSpan={12} className={'gridCell-padding'}>
                                    {user.declineReason &&
                                        <div className={'decline-reason'}>
                                            <b>Reason for Decline: </b>{user.declineReason}
                                        </div>
                                    }
                                    <Divider className={'grid-divider'} />
                                </GridCell>
                            </Grid>
                        ))
                        : (<div className={'loading-div'}> Loading... </div>)
                }
            </DialogContent>
            <DialogFooter className={'dialog-footer'}>
                <div>
                    <Button
                        id="dialog-cancelbtn"
                        className='closeButtonActive email-dialog-font'
                        onClick={disableEsignatureStatusDialog}
                    >
                        {CLOSE}
                    </Button>
                </div>
            </DialogFooter>
        </Dialog>
    );
}

export default EsignatureStatusDetailsDialog;