import React, { useState, useEffect } from 'react';
import { Card } from 'react-md';

import * as STRING from '@utilities/constants/strings';
import * as IMG from '@utilities/constants/images';
import { handleDownloadClick, getChecklist } from '@utilities/services/checklistService';

function ChecklistCard(props) {
    const { isDashboard, organizerId, isFutureYear } = props;

    const [checklistData, setChecklistData] = useState(null);

    useEffect(async () => {
        if (isFutureYear) {
            setChecklistData(null);
        } else {
            getChecklist(organizerId).then((checklist) => {
                setChecklistData(checklist);
            });
        }
    }, [isFutureYear]);

    const handleChecklistCardClick = () => {
        if (checklistData)
            handleDownloadClick(organizerId, checklistData.name)
    }

    return isDashboard ?
        (
            <Card
                className={`dashboardHeaderIconCard entryNoShadow dashboardHeaderCard checklistCard iconCard ${checklistData ? '' : ' disabledCard'}`}
                data-testid='checklist-card'
                onClick={handleChecklistCardClick}
            >
                <div className="dashboardHeaderIconName headerCardsPadding iconImg" >
                    <img alt='Checlist' src={IMG.CHECKLIST} />
                    <div className="iconText">{STRING.CHECKLIST}</div>
                </div>
            </Card>
        )
        : <></>;
}

export default ChecklistCard;