import { useLayoutEffect, useState, createRef, useEffect } from 'react';
import { GridCell } from 'react-md';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// this components' max width is fixed to fit 50 'w' characters before wrapping.
const StyledWidthToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: '565px',
    },
});

function GridCellEmailTooltip({ user, propertyName, index, colSpan, className }) {

    const ref = createRef();
    const [showToolTip, setShowTooltip] = useState(false);
    const [onHoverTooltip, setOnHoverTooltip] = useState(false);

    useLayoutEffect(() => {
        setShowTooltip(ref.current && ref.current.clientWidth < ref.current.scrollWidth);
    }, [ref]);

    const handleMouseEnter = () => {
        setOnHoverTooltip(true);
    };

    const handleMouseLeave = () => {
        setOnHoverTooltip(false);
    };

    const value = user[propertyName];
    const isEmailTextOverflowAndMouseHovered = showToolTip && onHoverTooltip;
    const gridCellClassName = `email-tooltip-${isEmailTextOverflowAndMouseHovered ? 'show' : 'hide'}`;

    return (
        <GridCell
            colSpan={colSpan}
            className={`email-checklist-cell grid-email-cell ${gridCellClassName} ${className ?? ''}`}
            data-testid={`email-checklist-${propertyName}-${index}`}
        >
            <StyledWidthToolTip
                title={<div className={'email-checklist-hover-tooltip'}>{value}</div>}
                placement="top"
                className="gridcell-email-tooltip"
                arrow
                open={isEmailTextOverflowAndMouseHovered}
                data-testid={`email-tooltip`}
            >
                <div
                    ref={ref}
                    className={"grid-email-cell"}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    data-testid={`email-tooltip-value`}
                >
                    {value}
                </div>
            </StyledWidthToolTip>
        </GridCell>
    );
}

export default GridCellEmailTooltip;