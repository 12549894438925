import { fromJS } from 'immutable';

export const initialState = fromJS({
    G_isAuthenticated: null,
    G_currentUser: null,
    G_currentUserData: null,
    G_clientProfile: false,
    G_toolbarTitle: 'Dashboard',
    G_screenSize: 'Desktop',
    G_year: 2024
});

export function globalReducer(state = initialState, action) {
    
    switch (action.type) {
    case 'expandedSubFormState': {
        const vehicleExpand = state.get('vehicleExpand') || {};
        const { formName, expandedState } = action.payload;

        vehicleExpand[formName] = expandedState ? true : false;
        return state.set('vehicleExpand', vehicleExpand);
    }
    default:
        return state.set(action.type, action.payload);
    }
}
