import Papa from 'papaparse';
import * as IMG from '@utilities/constants/images.js';
import '@components/organizerList/customToolbar/handleExportToCSV.css';
import api from '@utilities/claApi';
import * as STR from '@utilities/constants/strings';

const handleExportToCSV = async (dispatch, ACTION, orgQuery) => {
  let allOrganizers = [];
  let offset = 0;
  const limit = 250;
  const totalLimit = 1000;

  // function to fetch 1000 organizers
  const fetchOrganizers = async (query) => {

    while (allOrganizers.length < totalLimit) {
      try {
        dispatch(ACTION.setProgressVisible(true));
        const response = await api.get(`/organizers?${query}&limit=${limit}&offset=${offset}`);
        const organizers = response.data.results;

        if (organizers.length === 0) {
          break;
        }

        allOrganizers = [...allOrganizers, ...organizers];
        offset += limit;


        if (allOrganizers.length >= totalLimit) {
          allOrganizers = allOrganizers.slice(0, totalLimit);
          break;
        }
      } catch (error) {
        console.error('Error fetching organizers', error);
        break;
      }
    }
    dispatch(ACTION.setProgressVisible(false));
    return allOrganizers;
  }

  const fetchedOrganizers = await fetchOrganizers(orgQuery);

  if (!fetchedOrganizers || fetchedOrganizers.length === 0) {
    dispatch(ACTION.setCustomDialogMsg(
      <div className='exportErrorContainer'>
        <span className="errorHeaderText">No rows found</span>
      </div>
    ));
    dispatch(ACTION.setShowCustomDialog(true));
    return;
  }

  if (fetchedOrganizers.length >= totalLimit) {
    dispatch(ACTION.setCustomDialogTitle(
      <div className='exportErrorContainer'>
        <img className="warningDialogIcon"
          width="24"
          height="20"
          alt="Warning icon"
          src={IMG.WARNING_ICON}
        >
        </img>
        <span className="errorHeaderText">Error</span>
      </div>
    ));
    dispatch(ACTION.setCustomDialogMsg(
      <>
        <div className='exportErrorMsg'>
          You have selected to export more than 1000 rows maximum limit. Only the first 1000 rows will display in the export. You will need to visit{' '}
          <a
            href={process.env.REACT_APP_LEADERSHIP_DASHBOARD_URL}
            target='_blank'
            rel='noopener noreferrer'
            className='exchangeDashboardUrl'
          >
            <span className="styledLink">1040 Exchange Report Dashboard</span>
          </a>
          {' '}to initiate exports exceeding 1000 rows.
        </div>

      </>
    ))
    dispatch(ACTION.setShowCustomDialog(true));
  }



  // get data from rows
  const csvContent = fetchedOrganizers.map((org) => {
    const taxpayerEmail = org.taxpayer?.email ? org.taxpayer.email : '';
    const spouseEmail = org.spouse?.email ? org.spouse.email : '';
    const signerFirstName = org.signer?.first ? org.signer?.first : '';
    const signerLastName = org.signer?.last ? org.signer?.last : '';
    const signerName = signerFirstName + ' ' + signerLastName;
    const inChargeFirstName = org.inCharge?.first ? org.inCharge?.first : '';
    const inChargeLastName = org.inCharge?.last ? org.inCharge?.last : '';
    const inChargeName = inChargeFirstName + ' ' + inChargeLastName;
    const locked = org.locked ? 'Locked' : 'Not Locked';

    return {
      Name: org.client.name || '',
      TaxpayerEmail: taxpayerEmail,
      SpouseEmail: spouseEmail,
      CSL: org.client.csl || '',
      ClientId: org.client.number || '',
      Signer: signerName,
      InCharge: inChargeName,
      ExchangeStatus: org.customStatus || '',
      LastActivity: org.customDate || '',
      LockedStatus: locked,
      ESignature: org.taxReturnStatus || '',
    }
  });

  const csv = Papa.unparse(csvContent);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'Exchange Manager Export.csv';
  link.click();
}

export default handleExportToCSV;