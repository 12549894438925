// common modules
import { Button, Dialog } from 'react-md';

// custom modules
import '@components/dialog/customDialog/customDialog.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { DOWNLOADED_FILES_COMPLETED } from '@utilities/constants/strings';
import { useEffect } from 'react';

const multiUploadErrorProps = [
  'contentAndTypeMismatchFiles',
  'duplicateFilesSkipped',
  'extensionErrorFiles',
  'invalidFiles',
  'unknownErrorFiles',
];

function CustomDialog(props) {
  const { REDUX, ACTION, dispatch, selectState } = usePageFramework();
  const title = selectState(REDUX.CUSTOM_DIALOG_TITLE);
  const message = selectState(REDUX.CUSTOM_DIALOG_MSG);
  const uploadProgress = selectState(REDUX.UPLOAD_PROGRESS_VISIBLE);
  const isCloseButtonHidden = selectState(REDUX.HIDE_CUSTOM_DIALOG_CLOSE_BUTTON) || false;
  const isMultipleUpload = selectState(REDUX.IS_MULTIPLE_UPLOAD) || false;
  const isTryAgainVisible = selectState(REDUX.CUSTOM_DIALOG_TRY_AGAIN) || false;
  const tryAgainCallback = selectState(REDUX.CUSTOM_DIALOG_TRY_AGAIN_CALLBACK);
  const isMultipleUploadError = message?.props?.filesLength > 1 && multiUploadErrorProps.some(prop => prop in message?.props);
  let className = 'WarningDialogContainer submitToClaSuccessDialog';
  let messageClass = 'WarningDialogText1 SuccessDialogText'

  if (isMultipleUpload && isMultipleUploadError) {
    className += ' multipleFileErrorMessageDialog'
  }

  if (message === DOWNLOADED_FILES_COMPLETED) {
    messageClass += ' downloadComplete'
  }

  const handleCloseClick = () => {
    dispatch(ACTION.setShowCustomDialog(false));
    dispatch(ACTION.setDuplicateUploadFilesSkipped([]));
    dispatch(ACTION.setIsMultipleUpload(false))

    if (uploadProgress) dispatch(ACTION.setUploadProgressVisible(false));
  }

  useEffect(() => {
    return () => {
        // Clear "try again" logic on dialog destroy
        dispatch(ACTION.setCustomDialogTryAgain(false));
        dispatch(ACTION.setCustomDialogTryAgainCallback(null));
    }
  }, [props.visible]);

  return (
    <Dialog
      id="custom-dialog"
      defaultFocus="first"
      data-testid='custom-dialog'
      visible={props.visible}
      onRequestClose={() => { }}
      modal={true}
      aria-labelledby="dialog-title"
      className={className}
      style={isMultipleUpload ? { width: '750px' } : null}
    >
      <div className='WarningDialogInnerContainer' >
        <div className="SuccessDialogTitle" style={isMultipleUpload ? { textAlign: 'left' } : null}>{title}</div>
        <div className="WarningDialogTextContainer">
          <div className={messageClass}> {message}</div>
        </div>
        {
          !isCloseButtonHidden && !isTryAgainVisible && <div className='successDialogButton'>
            <Button className="formNotesSaveActive" id="dialog-close" data-testid='dialog-close'
              onClick={handleCloseClick}
            > Close
            </Button>
          </div>
        }
        {
          isTryAgainVisible && (
            <div className='successDialogButton tryAgainButtonContainer'>
              <Button className="customDialogButton formNotesSaveActive" id="dialog-close" data-testid='dialog-close'
                onClick={handleCloseClick}
              > Close
              </Button>

              <Button
                className="customDialogButton tryAgainButton"
                id="dialog-try-again"
                data-testid='dialog-try-again'
                onClick={() => {
                  tryAgainCallback?.();
                  handleCloseClick();
                }}
              >
                Try Again
              </Button>
            </div>
          )
        }
      </div>
    </Dialog>
  );
}

export default CustomDialog;