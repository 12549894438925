// common modules
import _ from 'lodash';

// custom modules
import api from '@utilities/claApi';
import * as STATUS from '@utilities/constants/cardStatus.js';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';

const calculateDashboardSummary = (dashboardId, dashboard, organizer, defaultDashboard) => {
  const getTodosCounter = (dashboard) => {
    const requiredForms = dashboard?.filter((category) => category?.title !== 'Opportunity Services')
      .map((cat) => cat?.cards.filter((card) => card?.isRequired)).flat(2) || [];

    const completed = dashboard?.filter((category) => category?.title !== 'Opportunity Services')
      .map((cat) => cat?.cards?.filter((card) => card?.isRequired && card?.statusId === STATUS.COMPLETED)).flat(2) || [];

    return `${completed.length} of ${requiredForms.length}` || '0 of 2';
  };

  const setRequiredTodos = (organizer) => {
    let dashboardCopy = _.cloneDeep(dashboard ?? defaultDashboard);
    let dashboardSummary = '0 of 2';

    return api.get(`/organizers/${organizer.id}/prior`).then((response) => {
      return response.data.data.taxData;
    }).then((priorData) => {
      dashboardCopy = setRequiredForms(priorData.priorYear, dashboardCopy);
      dashboardSummary = getTodosCounter(dashboardCopy);
      return {
        summary: dashboardSummary,
        dashboard: dashboardCopy,
      };
    }).catch((err) => {
      console.error(err);
      console.error('Unable to load prior year');
      dashboardSummary = getTodosCounter(dashboardCopy);
      return {
        summary: dashboardSummary,
        dashboard: dashboardCopy,
      };
    }).finally(() => {
      // save dashboard
      const data = { dashboard: dashboardCopy };
      api.put(`/organizers/${organizer.id ?? ''}/dashboard/${dashboardId}`, data)

      // save dashboard summary
      const summaryData = {
        dashboardSummary,
      };
      api.put(`/organizers/${organizer.id ?? ''}`, summaryData)
    });
  };

  // dashboard document in database already exists
  return dashboard && dashboard?.length > 0
    ? { // dashboard exists already
      summary: getTodosCounter(dashboard),
      dashboard,
    }
    : setRequiredTodos(organizer) // dashboard is null
};

export {
    calculateDashboardSummary,
}

