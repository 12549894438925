// common modules
import React, { useEffect, useRef } from 'react';
import { useToggle } from "@react-md/utils";
import { Button } from "@react-md/button";
import { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogFooter,
} from "@react-md/dialog";
import _ from 'lodash';

// custom modules
import './ManageOrganizers.css';
import * as STRING from '@utilities/constants/strings';
import Breadcrumb from '@components/Breadcrumb.js';
import api from '@utilities/claApi';
import CreateOrganizerDialog from '@components/adminDashboard/createOrganizerDialog/index.js';
import EditOrganizerDialog from '@components/adminDashboard/editOrganizerDialog/index.js';
import setDerivedOrganizerData from '@utilities/organizerData';
import usePageFramework from '@utilities/hooks/usePageFramework';
import SearchableRestfulTable from '@components/searchableRestfulTable/SearchableRestfulTable';
import UploadList from './uploadList';
import getDashboard from '@utilities/constants/dashboards';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';
import { clientDetailsSetter } from '@components/organizerExport/organizerRenderer/components/utils/getClientDetails';
import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import getYear from '@utilities/helpers/getYear';
import ErrorDialog from '@components/dialog/errorDialog';

function ManageOrganizers(props) {
  const {
    ACTION,
    NAV,
    dispatch,
    REDUX,
    updateUploadList,
    clearFormState,
    updatePage,
    selectState
  } = usePageFramework();
  const year = getYear(selectState)
  const [showDialog, enableDialog, disableDialog] = useToggle(false);
  const [showCreateDialog, enableCreateDialog, disableCreateDialog] = useToggle(false);
  const [showEditDialog, enableEditDialog, disableEditDialog] = useToggle(false);
  const [targetOrganizer, setTargetOrganizer] = useState(null);
  const [requestTableReload, toggleTableReload] = useToggle(false);
  const [dialog, setDialog] = useState({
    title: '',
    message: ''
  });
  const [confirmButtonText, setConfirmButtonText] = useState('Confirm');
  const [closeButtonText, setCloseButtonText] = useState('Close');
  const [showFilesAndOrgExport, setShowFilesAndOrgExport] = useState(false);
  const [orgExportIsGenerating, setOrgExportIsGenerating] = useState(false);
  const [activeOrganizer, setActiveOrganizer] = useState({
    clientName: '', clientNumber: '', year: ''
  });
  const [organizerId, setOrganizerId] = useState('');
  const componentRef = useRef(null);

  const resourceName = 'Organizer';
  const resourceUri = 'organizers';
  const searchFilter = 'client.number';
  const columns = [
      { key: 'status', text: 'Status', create: { show: true, required: true }, edit: { show: false } },
      { key: 'client.number', text: 'Client Number', create: { show: false }, edit: { show: false } },
      { key: 'client.name', text: 'Client', create: { show: false }, edit: { show: false } },
      { key: 'priorYearReturnId', text: 'Prior Year Tax return ID', create: { show: false }, edit: { show: false } },
      { key: 'year', text: 'Year', create: { show: true, required: true }, edit: { show: false } },
      { key: 'locked', text: 'Locked', type: 'boolean', create: { show: false, required: true }, edit: { show: true } }
  ];
  const defaultSortKey = 'client.lastName';
  const defaultSortOrder = 'ascending';

  const navItems = [
    { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
    { to: NAV.MANAGE_ORGANIZERS, label: 'Organizers', current: true }
  ];

  const startFilesAndOrgExport = async (organizer) => {
    // show loading
    dispatch(ACTION.setProgressText('Loading Export Data...'));
    dispatch(ACTION.setProgressVisible(true));

    setShowFilesAndOrgExport(false);
    // Get Organizer Form data into redux
    updatePage({ title: 'PDF Export', key: 'organizerExport' })
    clearFormState();

    setActiveOrganizer({
      clientName: organizer.client?.name,
      clientNumber: organizer.client?.number,
      year: organizer.year
    })

    // get organizer details
    api.get(`organizers/${organizer.id}`).then(async (response) => {
      let hasVehicleData = false;

      const {
        id: organizerId,
        forms,
        client,
        status,
        year,
        id,
        entryExperience,
      } = response.data;

      // get form notes
      await api.get(`/organizers/${organizerId}/notes`)
        .then(notesResponse => {
          const notes = notesResponse?.data?.results || [];
          dispatch(ACTION.setFormNotes(notes));
        })
        .catch(err => {
          console.log('Notes error: ', err.message);
          throw new Error(STRING.NOTES_ERROR_LOAD);
        });

      const {
        data: {
          id: dashboardId,
          dashboard,
        }
      } = await api.get(`organizers/${organizerId}/dashboard`);

      // set organizer meta data
      dispatch(ACTION.setYear(year));
      dispatch(ACTION.setActiveReturn({
        clientName: client.name,
        displayName: client?.name,
        clientNumber: client?.number ?? '',
        year: year,
        formStatus: status
      }));

      // get prior year data for organizer
      const priorData = await api.get(`/organizers/${organizer.id}/prior`).then((response) => {
        dispatch(ACTION.setPriorYearData(response.data.data.taxData.priorYear));
        return response.data.data.taxData;
      }).catch((err) => {
        console.error(err);
        throw new Error(err.message);
      });
      const dashboardObject = getDashboard(year.current)
      let dashboardCopy = _.cloneDeep(dashboard ?? dashboardObject.dashboard);
      if (priorData) dashboardCopy = setRequiredForms(priorData.priorYear, dashboardCopy);
      dispatch(ACTION.setDashboard(dashboardCopy));
      dispatch(ACTION.setDashboardId(dashboardId));

      const formKeys = [];
      forms?.forEach((form) => {
        if (!formKeys.includes(form.key)) {
          dispatch(ACTION.setForm(form.key, form.data));
          hasVehicleData = hasVehicleData || _.startsWith(form.key, REDUX.VEHICLE);
          formKeys.push(form.key);
        }
      });

      clientDetailsSetter.clientDetails = {
        clientName: client.name,
        clientNumber: client?.number ?? '',
      };
      // Load vehicles if the client does not have any vehicle data
      if (priorData && priorData.priorYear && !hasVehicleData) {
        const vehicleFormData = loadVehicleData(priorData.priorYear);
        if (vehicleFormData && Object.keys(vehicleFormData).length) {
          Object.entries(vehicleFormData).forEach(([vehicleKey, vehicleForm]) => {
            dispatch(ACTION.setForm(vehicleKey, vehicleForm));
          });
        }
      }
      dispatch(ACTION.setOrganizerId(id));
      setOrganizerId(id);
      dispatch(ACTION.setCompletedEntryStep(entryExperience?.completedStep));
    }).then(() => {
      dispatch(ACTION.setProgressText(''));
      dispatch(ACTION.setProgressVisible(false));
    }).catch((err) => {
      console.error(err);
			ErrorDialog(dispatch, ACTION, 'Error', err.message, true, () => startFilesAndOrgExport(organizer));
      dispatch(ACTION.setProgressText(''));
      dispatch(ACTION.setProgressVisible(false));
    })

    await updateUploadList(organizer.id).catch((err) => {
      console.error('Failed to retrieve uploads', err);
    });
    setShowFilesAndOrgExport(true);
  }

  const actions = [
    {
      label: 'Reset Form Data',
      onClick: (organizer) => {
        setDialog({
          title: 'Reset Form Data',
          message: 'Are you sure you want to reset form data?',
          allowConfirm: true,
          allowClose: true,
          onConfirm: () => {
            setDialog({
              title: 'Reset Form Data',
              message: 'Resetting form data...',
              allowConfirm: false,
              allowClose: false
            });

            api.get(`organizers/${organizer.id}`).then((response) => {
              return response.data.forms;
            }).then((forms) => {
              const formPromises = forms.map((form) => {
                return api.delete(`forms/${form.id}`);
              });

              const json = {
                entryExperience: {
                  completedStep: null,
                },
                isTermsAgreed: false,
                dashboardSummary: null,
                // lastUserActivityOn: lastUserActivityDateSetter.lastUserActivityDate,
                status: STRING.NOT_STARTED_TEXT,
                customStatus: STRING.NOT_STARTED_TEXT,
                customDate: STRING.NOT_AVAILABLE_LABEL,
                bannerDismissed: false
              };

              formPromises.push(api.put(`organizers/${organizer.id}`, json));
              return Promise.all(formPromises);
            }).then(() => {
              return api.get(`/organizers/${organizer.id}/documents`);
            }).then((response) => {
              const documents = response.data.results;

              const documentPromises = documents.map((document) => {
                return api.delete(`/organizers/${organizer.id}/documents/${document.id}`);
              });

              return Promise.all(documentPromises);
            }).then(() => {
              return api.get(`organizers/${organizer.id}/notes`);
            }).then((response) => {
              const notes = response.data.results;

              const notesPromises = notes.map((note) => {
                return api.delete(`organizers/${organizer.id}/notes/${note.id}`);
              });

              return Promise.all(notesPromises);
            }).then(() => {
              return api.get(`/organizers/${organizer.id}/dashboard`);
            }).then((response) => {
              const dashboardObj = response?.data;

              return api.put(`organizers/${organizer.id}/dashboard/${dashboardObj.id}`, { dashboard: null });
            }).then(() => {
              setDialog({
                title: 'Reset Form Data',
                message: 'Form data has been reset',
                allowClose: true
              });
            }).catch((error) => {
              console.error(error);
              setDialog({
                title: 'Reset Form Data',
                message: 'Failed to reset form data',
                allowClose: true
              });
            });
          }
        });

        enableDialog();
      }
    },
    {
      label: 'Reload Prior Year',
      onClick: (organizer) => {
        setDialog({
          title: 'Reload Prior Year Data',
          message: 'Reloading prior year data for organizer...',
          allowClose: false
        });

        enableDialog();

        api.post(`organizers/${organizer.id}/pull`, { returnType: 'I' }).then((response) => {
          setDialog({
            title: 'Reload Prior Year Data',
            message: 'Request to reload prior year data has been sent.',
            allowClose: true
          });
        }).catch((error) => {
          console.error(error);
          setDialog({
            title: 'Reload Prior Year Data',
            message: 'Failed to reload prior year data!',
            allowClose: true
          });
        })
      }
    },
    {
      label: 'Delete All Documents',
      onClick: (organizer) => {
        setDialog({
          title: 'Delete All Documents',
          message: 'Are you sure you want to delete all documents?',
          allowConfirm: true,
          allowClose: true,
          onConfirm: () => {
            setDialog({
              title: 'Delete All Documents',
              message: 'Deleting all documents...',
              allowConfirm: false,
              allowClose: false
            });

            api.get(`organizers/${organizer.id}/documents`).then((response) => {
              return response.data.results;
            }).then((documents) => {
              const documentPromises = documents.map((document) => {
                return api.delete(`/organizers/${organizer.id}/documents/${document.id}`);
              });

              return Promise.all(documentPromises);
            }).then(() => {
              setDialog({
                title: 'Delete All Documents',
                message: 'All documents have been deleted',
                allowClose: true
              });
            }).catch((error) => {
              console.error(error);
              setDialog({
                title: 'Delete All Documents',
                message: 'Failed to delete all documents',
                allowClose: true
              });
            });
          }
        });

        enableDialog();
      }
    },
    {
      label: 'Export Uploaded Files',
      onClick: (organizer) => startFilesAndOrgExport(organizer)
    },
    {
      label: 'Sync with Workflow',
      onClick: (organizer) => {
        setDialog({
          title: 'Sync with Workflow',
          message: 'Syncing Workflow data to organizer...',
          allowClose: false
        });

        enableDialog();
        const triggerBody = {
          type: STRING.WORKFLOW_SYNC_TRIGGER,
          data: {
            id: organizer.id,
          }
        };

        api.post(`/triggers`, triggerBody ).then((response) => {
          setDialog({
            title: 'Sync with Workflow',
            message: 'Request to sync with Workflow has been sent.',
            allowClose: true
          });
        }).catch((error) => {
          console.error(error);
          setDialog({
            title: 'Sync with Workflow',
            message: 'Failed to sync with Workflow!',
            allowClose: true
          });
        })
      }
    }
  ];

  const handleCreateOrganizer = (params) => {
    // build update object from parameters with values
    let updateObj = {};

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        updateObj[key] = params[key];
      }
    });

    api.post(`organizers`, updateObj).then((response) => {
      const groupObj = { groups: updateObj.groups };
      if (updateObj.groups) {
        api.put(`/organizers/${response.data.id}/groups`, groupObj).catch((error) => {
          console.error(error);
        });
      }

      disableCreateDialog();

      setDialog({
        title: 'Create Organizer',
        message: 'Organizer created',
        allowClose: true
      });
      enableDialog();
      toggleTableReload();
    }).catch((error) => {
      console.error(error);
      disableCreateDialog();

      setDialog({
        title: 'Create Organizer',
        message: 'Failed to create organizer',
        allowClose: true
      });

      enableDialog();
    });
  };

  const openEditDialog = (resource) => {
    if (!resource.id) {
      console.error('Resource to edit does not have an ID');
      return;
    }

    setTargetOrganizer(resource);
    enableEditDialog();
  };

  const handleEditOrganizerCancel = () => {
    setTargetOrganizer(null);
    disableEditDialog();
  };

  const handleEditOrganizerConfirm = (changes) => {
    const { lockedState, hasChangedLocked, assignedGroups, hasChangedGroups } = changes;
    const targetOrganizerId = targetOrganizer.id;

    const promises = [];

    if (hasChangedLocked) {
      promises.push(api.put(`/organizers/${targetOrganizerId}`, { locked: lockedState }));
    }

    if (hasChangedGroups) {
      promises.push(api.put(`/organizers/${targetOrganizerId}/groups`, { groups: assignedGroups }));
    }

    handleEditOrganizerCancel();
    setDialog({
      title: 'Edit Organizer',
      message: 'Updating organizer...',
      allowClose: false
    })
    enableDialog();

    Promise.all(promises).then(() => {
      return setDerivedOrganizerData(targetOrganizerId);
    }).then(() => {
      setDialog({
        title: 'Edit Organizer',
        message: 'The organizer was sucessfully updated',
        allowClose: true
      })
      enableDialog();
      toggleTableReload();
    }).catch((err) => {
      console.error(`An error has occurred while editing organizer ${targetOrganizer.id}`);
      console.error(err);
      setDialog({
        title: 'Edit Organizer',
        message: 'An error has occurred while updating this organizer',
        allowClose: true
      })
      enableDialog();
    }).finally(() => {
      setTargetOrganizer(null);
    });
  };

  const handleCloseButtontext = () => {
    setCloseButtonText('Close');
    setConfirmButtonText('Confirm');
    disableDialog();
    setShowFilesAndOrgExport(false);
    clearFormState();
  }

  const confirmFileExport = async () => {
    setOrgExportIsGenerating(true)
  }

  useEffect(() => {
    if (orgExportIsGenerating) {
      console.log('OrganizerExport isGenerating: ', orgExportIsGenerating);
      dispatch(ACTION.setProgressText('Generating PDF...'));
      dispatch(ACTION.setProgressVisible(true));
    }
    // eslint-disable-next-line  
  }, [orgExportIsGenerating]);

  return (
    <div className="pracDashboardSize">
      <Breadcrumb items={navItems} />
      <h1>Organizers</h1>
      <CreateOrganizerDialog visible={showCreateDialog} onCreate={handleCreateOrganizer} onClose={disableCreateDialog} />
      <EditOrganizerDialog visible={showEditDialog} onConfirm={handleEditOrganizerConfirm} onCancel={handleEditOrganizerCancel} targetOrganizer={targetOrganizer} />
      <Dialog
        ref={componentRef}
        id="organizer-action-dialog"
        role="alertdialog"
        visible={showDialog}
        onRequestClose={() => { }}
        aria-labelledby="organizer-action-dialog-title"
        data-testid="organizer-action-dialog-title"
      >
        <DialogHeader>
          <DialogTitle>{dialog.title}</DialogTitle>
        </DialogHeader>
        <DialogContent>{dialog.message}</DialogContent>
        <DialogFooter>
          <Button id="organizer-action-dialog-confirm" theme="warning" disabled={!dialog.allowConfirm} onClick={dialog.onConfirm}>{confirmButtonText}</Button>
          <Button id="organizer-action-dialog-close" onClick={handleCloseButtontext} disabled={!dialog.allowClose}>
            {closeButtonText}
          </Button>
        </DialogFooter>
      </Dialog>
      <SearchableRestfulTable
        resourceName={resourceName}
        resourceUri={resourceUri}
        columns={columns}
        defaultSortKey={defaultSortKey}
        defaultSortOrder={defaultSortOrder}
        createOverRide={enableCreateDialog}
        editOverRide={openEditDialog}
        allowRowClick={true}
        actions={actions}
        reloadTable={requestTableReload}
        searchFilter={searchFilter}
        exportFilesAndOrgExport={true}
        setShowFilesAndOrgExport={setShowFilesAndOrgExport}
      />
      <hr />
      {showFilesAndOrgExport && <div>
        <div
          className="file-export-button">
          <Button
            theme="clear"
            themeType="outline"
            onClick={confirmFileExport}
            disabled={orgExportIsGenerating}
          >
            {orgExportIsGenerating ? 'generating...' : 'Download Files'}
          </Button>
        </div>


        <UploadList isGenerating={orgExportIsGenerating} setIsGenerating={setOrgExportIsGenerating} activeOrganizer={activeOrganizer} addOrgExport={true} organizerId={organizerId} />
        <div
          className="file-export-button">
          <Button
            theme="clear"
            themeType="outline"
            onClick={confirmFileExport}
            disabled={orgExportIsGenerating}
          >
            {orgExportIsGenerating ? 'generating...' : 'Download Files'}
          </Button>
        </div>
      </div>}
    </div>
  );
}

export default ManageOrganizers;