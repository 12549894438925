export const DEFAULT = '/';
export const DASHBOARD = '/dashboard';
export const CLIENT_DASHBOARD = '/dashboard/:orgId';
export const ORGANIZER_EXPORT = '/export';
export const PRACTITIONERS_DASHBOARD = '/practitionersDashboard';
export const ADMIN_DASHBOARD = '/admin';
export const MANAGE_CLIENTS = '/admin/clients';
export const MANAGE_ORGANIZERS = '/admin/organizers';
export const MANAGE_NOTES = '/admin/notes';
export const MANAGE_FORMS = '/admin/forms';
export const MANAGE_GROUPS = '/admin/groups';
export const MANAGE_USERS = '/admin/users';
export const MANAGE_IN_CHARGE = '/admin/inCharge';
export const MANAGE_ORGANIZER_STATUS = '/admin/manageOrganizerStatus';
export const PRIOR_YEAR_DATA_PULL = '/admin/priorYearDataPull';
export const MANAGE_NOTIFICATIONS = '/admin/notifications';
export const ENTRY_INTERVIEW = `${DASHBOARD}#welcome`;
export const ABOUT_YOU = '/aboutyou';
export const MY_PROFILE = '/myprofile';
export const SETTINGS = '/settings';
export const LOGIN = '/login';
export const WAGES = '/wages';
export const RETIREMENT_INCOME = '/retirementIncome';
export const MISCELLANEOUS_INCOME = '/miscellaneousIncome';
export const CHILDCARE = '/childcare';
export const DEPENDENTS = '/dependents';
export const MORTGAGE_INVESTMENT_INTEREST = '/mortgageInvestmentInterest';
export const ABOUT_YOUR_YEAR = '/aboutyouryear';
export const CHARITABLE_CONTRIBUTIONS = '/charitableContributions';
export const VEHICLEENERGY = '/vehicleenergy';
export const HOUSEHOLDEMPLOYMENTTAXES = '/householdEmploymentTaxes';
export const PROPERTYANDOTHERSTATETAXES = '/propertyAndOtherStateTaxes';
export const MEDICALEXPENSES = '/medicalExpenses';
export const RETIREMENT_CONTRIBUTIONS = '/retirementContributions';
export const REFUNDTAXPAYMENT = '/refundTaxPayment';
export const INCOME = '/income';
export const TUITION = '/tuition';
export const OTHERDEDUCTIONS = '/otherDeductions';
export const BUSINESS_INCOME = '/businessIncome';
export const FARM_INCOME = '/farmIncome';
export const RENTAL_INCOME = '/rentalIncome';
export const PASSTHROUGH_INCOME = '/passthroughIncome';
export const TAX_PAYMENTS = '/taxPayments';
export const ADD_CLIENT_FORM = '/addClientForm';
export const EDIT_CLIENT_FORM = '/editClientForm';
export const FOREIGN_ASSETS = '/foreignAssets';
export const FOREIGN_INCOME = '/foreignIncome';
export const DOCUMENT_MANAGER = '/documentManager';
export const HOME = `/home.js`;
export const CACHE_BREAK = `/cachebreak.js`;
export const WELCOME_EMAIL_INVITES = `/welcomeEmailInvites`;
export const CHQ_CHECKLIST_DOWNLOAD = `/chq-checklist-download`;
export const DOCUSIGN_EMBEDDED_SIGNING = `/docusign-embedded-signing`;

// ERROR PAGES
export const REQUEST_ERROR = '/error/request-error';
export const ACCESS_TO_CLIENT_ERROR = '/error/accessToClient';