import { EXCHANGE_1040 } from '@utilities/constants/strings';
import './headerImage.css';

function BlankHeaderImage() {
    return (
        <div className="imgContainer blankheader">
            <div className="clientInfoHeaderContainer" >
                <div className='dashboardTitle'>
                    <p id="headerInfo">
                        {EXCHANGE_1040}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default BlankHeaderImage;