// common modules
import React from 'react';
import { Link } from 'react-router-dom';

// custom modules
import '@components/BreadcrumbItem.css';

function BreadcrumbItem(props) {
	const { to, label, current } = props;

	return (
		<li className='breadcrumb-item'>
			{current ? (
				<span className='breadcrumb-item-link-current' style={{ cursor: 'default' }}>{label}</span>
			) : (
				<Link to={to} key={to} className='breadcrumb-item-link'>{label}</Link>
			)}
		</li>
	)
};

export default BreadcrumbItem;