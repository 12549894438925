// import Organizer from '@components/organizerExport/organizer';
import { Button } from 'react-md';
import { useState, useEffect, lazy, Suspense } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import getDashboard from '@utilities/constants/dashboards';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';
import { clientDetailsSetter } from '@components/organizerExport/organizerRenderer/components/utils/getClientDetails';
import api from '@utilities/claApi';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import getYear from '@utilities/helpers/getYear';

// Importing organizer directly per react docs
const Organizer = lazy(() => import('@components/organizerExport/organizer'));

function OrganizerExport() {
    const [isGenerating, setIsGenerating] = useState(false);
    const { dispatch, REDUX, ACTION, updatePage, clearFormState, history, updateUploadList, selectState } = usePageFramework();
    const { orgId } = useParams();
    const organizerId = orgId || history.location.state?.organizerId;
    const [activeOrganizer, setActiveOrganizer] = useState({
        clientName: '', clientNumber: '', year: ''
    });
    const year = getYear(selectState);

    useEffect(() => {
        if (isGenerating) {
            console.log('OrganizerExport isGenerating: ', isGenerating);
            dispatch(ACTION.setProgressText('Generating PDF...'));
            dispatch(ACTION.setProgressVisible(true));
        }
        // eslint-disable-next-line  
    }, [isGenerating]);

    useEffect(() => {
        updatePage({ title: 'PDF Export', key: 'organizerExport' });
        clearFormState();
        // get organizer details
        api.get(`organizers/${organizerId}`).then(async (response) => {
            let hasVehicleData = false;

            const {
                forms,
                client,
                status,
                year,
                id,
                entryExperience,
            } = response.data;

            const {
                data: {
                    id: dashboardId,
                    dashboard,
                }
            } = await api.get(`organizers/${organizerId}/dashboard`);

            // set organizer meta data
            api.get(`organizers/${organizerId}/notes`).then((response) => {
                dispatch(ACTION.setFormNotes(response.data.results));
            })

            dispatch(ACTION.setYear(year));
            dispatch(ACTION.setActiveReturn({
                clientName: client.name,
                displayName: client?.name,
                clientNumber: client?.number ?? '',
                year: year,
                formStatus: status
            }));

            // get prior year data for organizer
            const priorData = await api.get(`/organizers/${organizerId}/prior`).then((response) => {
                dispatch(ACTION.setReloadPriorData(true));
                dispatch(ACTION.setPriorYearData(response.data.data.taxData.priorYear));
                return response.data.data.taxData;
            }).catch((err) => {
                console.error(err);
            });
            const dashboardObject = getDashboard(year.current)
            let dashboardCopy = _.cloneDeep(dashboard ?? dashboardObject.dashboard);
            if (priorData) dashboardCopy = setRequiredForms(priorData.priorYear, dashboardCopy);
            dispatch(ACTION.setDashboard(dashboardCopy));
            dispatch(ACTION.setDashboardId(dashboardId));

            const formKeys = [];
            forms?.forEach((form) => {
                if (!formKeys.includes(form.key)) {
                    dispatch(ACTION.setForm(form.key, form.data));
                    hasVehicleData = hasVehicleData || _.startsWith(form.key, REDUX.VEHICLE);
                    formKeys.push(form.key);
                }
            });

            setActiveOrganizer({
                clientName: client.name,
                clientNumber: client?.number ?? '',
                year: year
            });

            clientDetailsSetter.clientDetails = {
                clientName: client.name,
                clientNumber: client?.number ?? '',
            };
            // Load vehicles if the client does not have any vehicle data
            if (priorData && priorData.priorYear && !hasVehicleData) {
                const vehicleFormData = loadVehicleData(priorData.priorYear);
                if (vehicleFormData && Object.keys(vehicleFormData).length) {
                    Object.entries(vehicleFormData).forEach(([vehicleKey, vehicleForm]) => {
                        dispatch(ACTION.setForm(vehicleKey, vehicleForm));
                    });
                }
            }

            dispatch(ACTION.setOrganizerId(id));
            dispatch(ACTION.setCompletedEntryStep(entryExperience?.completedStep));
            return updateUploadList(organizerId);
        }).then(() => {
            dispatch(ACTION.setProgressVisible(false));
        }).catch((err) => {
            console.error(err);
            dispatch(ACTION.setProgressVisible(false));
        })
        // eslint-disable-next-line
    }, []);

    const handleClick = () => {
        setIsGenerating(true);
    }

    return (
        <Suspense fallback={<div><i>Loading...</i></div>}>
            <Button
                theme="clear"
                themeType="outline"
                onClick={handleClick}
                disabled={isGenerating}
            >
                {isGenerating ? 'generating...' : 'Generate PDF'}
            </Button>
            <Organizer isGenerating={isGenerating} setIsGenerating={setIsGenerating} activeOrganizer={activeOrganizer} />
        </Suspense>
    )
}

export default OrganizerExport;