import * as REDUX from '@utilities/constants/redux';

export const buildPayloadObj = (type, payload, options = {}) => {
	return { type, payload, ...options };
};
export const setFileCategoryCount = (payload) => buildPayloadObj(REDUX.FILE_CATEGORY_COUNT, payload);
export const setisAuthenticated = (payload) => buildPayloadObj(REDUX.IS_AUTHENTICATED, payload);
export const setIsPractitioner = (payload) => buildPayloadObj(REDUX.IS_PRACTITIONER, payload);
export const setIsClient = (payload) => buildPayloadObj(REDUX.IS_CLIENT, payload);
export const setIsAdmin = (payload) => buildPayloadObj(REDUX.IS_ADMIN, payload);
export const setAuthUserEmail = (payload) => buildPayloadObj(REDUX.AUTH_USER_EMAIL, payload);
export const setScreenSize = (payload) => buildPayloadObj(REDUX.SCREEN_SIZE, payload);
export const setToolbarTitle = (payload) => buildPayloadObj(REDUX.TOOLBAR_TITLE, payload);
export const showBulkUpload = (payload) => buildPayloadObj(REDUX.SHOW_BULK_UPLOAD_CONTROL, payload);
export const setCustomDialogTitle = (payload) => buildPayloadObj(REDUX.CUSTOM_DIALOG_TITLE, payload);
export const setCustomDialogMsg = (payload) => buildPayloadObj(REDUX.CUSTOM_DIALOG_MSG, payload);
export const setCustomDialogTryAgain = (payload) => buildPayloadObj(REDUX.CUSTOM_DIALOG_TRY_AGAIN, payload);
export const setCustomDialogTryAgainCallback = (payload) => buildPayloadObj(REDUX.CUSTOM_DIALOG_TRY_AGAIN_CALLBACK, payload);
export const setHideCustomDialogCloseButton = (payload) => buildPayloadObj(REDUX.HIDE_CUSTOM_DIALOG_CLOSE_BUTTON, payload);
export const setShowCustomDialog = (payload) => buildPayloadObj(REDUX.SHOW_CUSTOM_DIALOG, payload);
export const setIsMultipleUpload = (payload) => buildPayloadObj(REDUX.IS_MULTIPLE_UPLOAD, payload);
export const setDashboardSortRequirements = (payload) => buildPayloadObj(REDUX.DASHBOARD_SORT_REQ, payload);
export const setDashboard = (payload) => buildPayloadObj(REDUX.DASHBOARD, payload);
export const setProgressVisible = (payload) => buildPayloadObj(REDUX.PROGRESS_VISIBLE, payload);
export const setProgressText = (payload) => buildPayloadObj(REDUX.PROGRESS_TEXT, payload);
export const setYear = (payload) => buildPayloadObj(REDUX.YEAR, payload);
export const setActiveReturn = (payload) => buildPayloadObj(REDUX.ACTIVE_RETURN, payload);
export const setFutureYear = (payload) => buildPayloadObj(REDUX.IS_FUTURE_YEAR, payload);
export const setActiveClientId = (payload) => buildPayloadObj(REDUX.ACTIVE_CLIENT_ID, payload);
export const setLockForms = (payload) => buildPayloadObj(REDUX.LOCK_FORMS, payload);
export const setPriorYearData = (payload) => buildPayloadObj(REDUX.PRIOR_YEAR_DATA, payload);
export const setReloadPriorData = (payload) => buildPayloadObj(REDUX.RELOAD_PRIOR_DATA, payload);
export const setPriorYearDetails = (payload) => buildPayloadObj(REDUX.PRIOR_YEAR_DETAILS, payload);
export const setUploadProgressVisible = (payload) => buildPayloadObj(REDUX.UPLOAD_PROGRESS_VISIBLE, payload);
export const setLockingPermission = (payload) => buildPayloadObj(REDUX.LOCKING_PERMISSON, payload);
export const setIsSubmittedToCLA = (payload) => buildPayloadObj(REDUX.IS_SUBMITTED_TO_CLA, payload);

export const setCurrentCardKey = (payload) => buildPayloadObj(REDUX.CURRENT_CARD_KEY, payload);
export const setCurrentCardStatus = (payload) => buildPayloadObj(REDUX.CURRENT_CARD_STATUS, payload);
export const setCard = (payload) => buildPayloadObj(REDUX.CURRENT_CARD, payload);
export const setDashboardFilterStatus = (payload) => buildPayloadObj(REDUX.DASHBOARD_FILTER_STATUS, payload);
export const setOrganizerId = (payload) => buildPayloadObj(REDUX.ORGANIZER_ID, payload);
export const setIsSaveSuccess = (payload) => buildPayloadObj(REDUX.IS_SAVE_SUCCESS, payload);
export const setForm = (formName, payload) => buildPayloadObj(formName, payload);
export const setLastSaveFormTime = (payload) => buildPayloadObj(REDUX.LAST_SAVE_FORM_TIME, payload);
export const setClientMaritalStatus = (payload) => buildPayloadObj(REDUX.CLIENT_MARITAL_STATUS, payload);
export const setCompletedEntryStep = (payload) => buildPayloadObj(REDUX.COMPLETED_ENTRY_STEP, payload);
export const setHasSkipEntryStep = (payload) => buildPayloadObj(REDUX.HAS_SKIP_ENTRY_STEP, payload);
export const setExpandedSubFormState = (formName, expandedState) => buildPayloadObj('expandedSubFormState', { formName, expandedState });
export const setIsCurrentFormNa = (payload) => buildPayloadObj(REDUX.IS_CURRENT_FORM_NA, payload);

export const setUploadList = (payload) =>
	buildPayloadObj(REDUX.UPLOAD_LIST, payload);

export const setFormNotes = (payload) => buildPayloadObj(REDUX.FORM_NOTES, payload);
// organizerExport Error Codes Page 
export const setErrorsForOrgExportAppendix = (payload) => buildPayloadObj(REDUX.SET_ORG_EXPORT_ERRORS, payload);

export const setUploadWarningVisible = (payload) => buildPayloadObj(REDUX.UPLOAD_WARNING_VISIBLE, payload);
export const setDuplicateUploadFiles = (payload) => buildPayloadObj(REDUX.DUPLICATE_UPLOAD_FILES, payload);
export const setDuplicateUploadFilesSkipped = (payload) => buildPayloadObj(REDUX.DUPLICATE_UPLOAD_FILES_SKIPPED, payload);
export const setUploadsProps = (payload) => buildPayloadObj(REDUX.UPLOADS_PROPS, payload);
export const setDashboardId = (payload) => buildPayloadObj(REDUX.DASHBOARD_ID, payload);
export const setNavBarCards = (payload) => buildPayloadObj(REDUX.NAVBAR_CARDS, payload);
export const setTaxpayerEmail = (payload) => buildPayloadObj(REDUX.TAXPAYER_EMAIL, payload);
export const setSpouseEmail = (payload) => buildPayloadObj(REDUX.SPOUSE_EMAIL, payload);
export const setTaxReturnStatus  = (payload) => buildPayloadObj(REDUX.TAX_RETURN_STATUS, payload);
export const setBannerDismissed = (payload) => buildPayloadObj(REDUX.BANNER_DISMISSED, payload);
export const setTaxpayerName = (payload) => buildPayloadObj(REDUX.TAXPAYER_NAME, payload);
export const setTaxPayerSpouseName = (payload) => buildPayloadObj(REDUX.TAXPAYER_SPOUSE_NAME, payload);
export const setpriorYearReturnId = (payload) => buildPayloadObj(REDUX.PRIOR_RETURN_ID, payload);
export const setTaxPayerInitials = (payload) => buildPayloadObj(REDUX.TAXPAYER_INITIALS, payload);
export const setHasMultipleReturns = (payload) => buildPayloadObj(REDUX.HAS_MULTIPLE_RETURNS, payload);
export const setSelectedOrganizer = (payload) => buildPayloadObj(REDUX.SELECTED_ORGANIZER, payload);
export const setEntryModalOpen = (payload) => buildPayloadObj(REDUX.ENTRY_MODAL_OPEN, payload);
export const setAuthorizedIds = (payload) => buildPayloadObj(REDUX.AUTHORIZED_IDS, payload);
export const setClientOrganizers = (payload) => buildPayloadObj(REDUX.CLIENT_ORGANIZERS, payload);
export const setIsTermsAgreed = (payload) => buildPayloadObj(REDUX.IS_TERMS_AGREED, payload);
export const setClientHqTodos = (payload) => buildPayloadObj(REDUX.CLIENT_HQ_TODOS, payload);
