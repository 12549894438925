export const BINARY = 'binary';
export const UNARY = 'unary';
// Constant query states below are used for the restful table component
export const MAX_PREFETCH_LIMIT = 100; // This is the max of records returned by the API
export const DEFAULT_PAGE_LIMIT = 25;
export const DEFAULT_FILTER_QUERY = [];
export const DEFAULT_SORT_QUERY = {};

export const SORT_TO_DATA_MAP = {
  client: {
    name: 'name',
    number: 'number',
    csl: 'csl'
  },
  group: {
    name: 'name',
    displayName: 'displayName',
    aadId: 'aadId'
  },
  organizer: {
    status: 'status',
    clientNumber: 'client.number',
    name: 'client.displayName',
    year: 'year',
    locked: 'locked',
  },
  user: {
    first: 'first',
    middle: 'middle',
    last: 'last',
    email: 'email',
    userLockPermission: 'permissions.ORGANIZER_LOCK',
  }
};

export const FILTER_TO_DATA_MAP = {
  client: {
    name: 'name',
    number: 'number',
    csl: 'csl'
  },
  group: {
    name: 'name',
    displayName: 'displayName',
    aadId: 'aadId'
  },
  organizer: {
    status: 'status',
    clientNumber: 'client.number',
    name: 'client.displayName',
    year: 'year',
    locked: 'locked',
  },
  user: {
    first: 'first',
    middle: 'middle',
    last: 'last',
    email: 'email',
  }
};

export const OPERATORS_MAP = {
  contains: { key: 'co', type: BINARY },
  equals: { key: 'eq', type: BINARY },
  startsWith: { key: 'sw', type: BINARY },
  endsWith: { key: 'ew', type: BINARY },
  isEmpty: { key: 'ie', type: UNARY },
  isNotEmpty: { key: 'ine', type: UNARY },
};

export const OPERATORS_LIST = Object.keys(OPERATORS_MAP);