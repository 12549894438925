import { dashboard as  Dashboard2021 } from './2021/dashboard';
import { dashboard as  Dashboard2022 } from './2022/dashboard';
import { dashboard as  Dashboard2023 } from './2023/dashboard';
import { dashboard as  Dashboard2024 } from './2024/dashboard';

const getDashboardForYear = (year = 2024, dashboards) => {
    const yearDashboard = dashboards[year] || [];
    return yearDashboard;
};

function getDashboard(year) {
    const dashboardByYear = {
        2021: Dashboard2021,
        2022: Dashboard2022,
        2023: Dashboard2023,
        2024: Dashboard2024
    }
    let  dashboard = getDashboardForYear(year, dashboardByYear);
    const dashboardCards = dashboard.flatMap((item) => item.cards);
    return { dashboard, dashboardCards }
}
export default getDashboard;