import {
  Button
} from 'react-md'
import _ from 'lodash'
import { useState, useEffect } from 'react';

import EmailInviteSuccessDialog from '@components/dialog/emailInviteSuccessDialog'
import {
  DataGridPro, gridClasses
} from '@mui/x-data-grid-pro';
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "@react-md/dialog";
import { formatStatus } from '@utilities/helpers/formatStatus.js';
import { WELCOME_EMAIL_DEACTIVATE_SUCCESS_DIALOG_WARNING_MESSAGE, WELCOME_EMAIL_SUCCESS_DIALOG_WARNING_MESSAGE } from '@utilities/constants/welcomeEmailInvites';

function DeactivateDialogContent(props) {
  const {
    currentCslData,
    setDialogVisible,
    handleDeactivation,
    setSelectedRows,
    setSelectionModel,
    onSelectionEmailInviteModalChange,
    selectedConfirmedEmailInviteItems,
    clsStatusDialog,
    hideSuccessDialog,
    cslStatus,
    isProcessingActivateDeactivate
  } = props

  const [displayDialog, setDisplayDialog] = useState(false);
  const dialogText = cslStatus === 'success' ? 'CSL(s) Deactivated Successfully!' : 'error occured';
  
  useEffect(() => {
    setDisplayDialog(clsStatusDialog);
  }, [clsStatusDialog])

  const closeDialog = () => {
    setDialogVisible(false)
    setDisplayDialog(false)
    hideSuccessDialog()
    setSelectedRows([])
    setSelectionModel([])
  }

  const errorModalCloseDialog = () => {
    setDialogVisible(false)
    setDisplayDialog(false)
    hideSuccessDialog()
  }

  const tryAgainDialog = () => {
    hideSuccessDialog()
    setDialogVisible(true);
  }

  const handleCancel = () => {
    setDialogVisible(false);
  }
  const confirmColumnsGrid = [
    { field: 'name', headerName: 'CSL', type: 'text', width: 550, editable: false, canEdit: false, required: true, },
    {
      field: 'status',
      headerName: 'Status',
      type: 'text',
      width: 300,
      editable: false,
      canEdit: false,
      valueFormatter: (params) => formatStatus(params.value),
    },
  ];
  return (
    <div className="welcomeEmailActDeactHeaderContainer">
      <Dialog
        id="welcome-email-actdeact-dialog"
        className="welcome-email-deact-dialog"
        role="alertdialog"
        visible={setDialogVisible}
        onRequestClose={() => { }}
        aria-labelledby="welcome-email-actdeact-dialog-title"
        data-testid="welcome-email-actdeact-dialog-title">
        <DialogContent>
          <div style={{ fontSize: '21px', fontWeight: 'bold', fontFamily: 'Georgia', color: '#2e334e' }}>Deactivate Welcome Email</div>
          <div style={{ lineHeight: "2.5" }}>Deactivate automatic sending for welcome emails for the following CSLs. </div></DialogContent>
        <div style={{ height: '200px', overflow: 'auto' }} data-testid='welcome-email-actdeact-table'>
          <DataGridPro
            autoHeight={false}
            checkboxSelection
            onRowSelectionModelChange={onSelectionEmailInviteModalChange}
            rows={currentCslData?.length > 0 ? currentCslData : []}
            columns={confirmColumnsGrid}
            columnVisibilityModel={{}}
            disableColumnResize={true}
            rowSelectionModel={selectedConfirmedEmailInviteItems?.size > 0 ? [...selectedConfirmedEmailInviteItems] : []}
            disableColumnSelector
            hideFooter={true}
            localeText={{ toolbarFilters: "" }}
            autoPageSize={true}
            disableVirtualization
            loading={isProcessingActivateDeactivate}
            sx={{
              overflowX: 'hidden',
              [`& .${gridClasses.row}.Mui-selected `]: {
                bgcolor: '#ffffff',
              },
              [`& .MuiDataGrid-virtualScroller`]: {
                overflowX: 'hidden !important', //removes horizontal scrollbar when the loading spinner appears
              },
            }}
            isRowSelectable={() => !isProcessingActivateDeactivate} // Disable all checkboxes in rows if disableAllRows is true
            componentsProps={{
              baseCheckbox: {
                sx: {
                  '&.Mui-disabled': {
                    color: '#D6D6D6', // Change colour of all checkboxes when disabled including the column header checkbox
                  },
                },
                disabled: isProcessingActivateDeactivate, // Disable the header checkbox
              },
              headerCheckbox: {
                disabled: isProcessingActivateDeactivate, // Disable the header checkbox
              },
            }}
          />
        </div>
        <DialogFooter className='welcome-email-actdeact-footer'>
          <div className="welcome-email-actdeact-btns">
            <Button id="welcome-email-cancel" className={isProcessingActivateDeactivate ? 'disabledCancelButtonInActive1' : 'cancelButtonInActive1'} disabled={isProcessingActivateDeactivate} onClick={handleCancel}>Cancel</Button>
            <Button id="welcome-email-deactivate" className={isProcessingActivateDeactivate ? 'disabledEmailInvites-activate' : 'emailInvites-activate'}
              disabled={isProcessingActivateDeactivate}
              onClick={() => {
                handleDeactivation()
              }}>Deactivate</Button>
          </div>
        </DialogFooter>
      </Dialog>
      <EmailInviteSuccessDialog className='formNotesSaveActive' warningMessage={WELCOME_EMAIL_DEACTIVATE_SUCCESS_DIALOG_WARNING_MESSAGE}  warningMessageText={WELCOME_EMAIL_SUCCESS_DIALOG_WARNING_MESSAGE} subtitleText={dialogText} onCloseClick={closeDialog} errorModalOnCloseClick={errorModalCloseDialog} onTryAgainClick={tryAgainDialog}
        visible={displayDialog} cslStatus={cslStatus} />
    </div>
  )
}
export default DeactivateDialogContent;