import { Button, Dialog } from 'react-md';

// custom modules
import '@components/dialog/emailInviteSuccessDialog/emailInviteSuccessDialog.css';
import { WARNING_ICON } from '@utilities/constants/images';

const DialogButton = ({ onClick, className, children, testId }) => (
    <Button
        className={className}
        id={`dialog-${testId}`}
        data-testid={`dialog-${testId}`}
        onClick={onClick}
    >
        {children}
    </Button>
);

const DialogTitle = ({ isSuccess, isFailure }) => {
    if (isSuccess) {
        return <div className="successDialogTitle"></div>;
    }
    if (isFailure) {
        return (
            <div className="welcomeEmailFailureDialogTitle">
                <img
                    className="warningDialogIcon"
                    width="24"
                    height="20"
                    alt="Warning icon"
                    src={WARNING_ICON}
                />
                Error
            </div>
        );
    }
    return null;
};

const DialogContent = ({ isFailure, warningMessage, warningMessageText, subtitleText }) => (
    <div className={isFailure ? 'welcomeEmailContentContainer cslFailureDialogText' : 'successWelcomeEmailContentContainer cslSuccessDialogText'}>
        {isFailure ? (
            <>
                <span>{warningMessage}</span>
                <span>{warningMessageText}</span>
            </>
        ) : (
            <span>{subtitleText}</span>
        )}
    </div>
);

function EmailInviteSuccessDialog({
    buttonElement,
    className,
    buttonType,
    onClick,
    subtitleText = '',
    onCloseClick,
    errorModalOnCloseClick,
    onTryAgainClick,
    visible,
    cslStatus,
    warningMessage,
    warningMessageText
}) {
    const isSuccess = cslStatus === 'success'
    const isFailure = cslStatus === "failure";

    const handleAction = (callback) => () => {
        callback?.();
    };

    return (
        <>
            {buttonElement && (
                <Button
                    buttonType={buttonType}
                    className={className}
                    data-testid="test-button"
                    onClick={handleAction(onClick)}
                >
                    {buttonElement}
                </Button>
            )}

            <Dialog
                id="success-dialog"
                role="alertdialog"
                visible={visible}
                onRequestClose={() => { }}
                aria-labelledby="dialog-title"
                className="WarningDialogContainer"
                data-testid="success-dialog"
            >
                <div className={`welcomeEmailDialogContainer ${isFailure ? '' : 'successWelcomeEmailDialogContainer'}`}>
                    <DialogTitle isSuccess={isSuccess} isFailure={isFailure} />

                    <DialogContent
                        isFailure={isFailure}
                        warningMessage={warningMessage}
                        warningMessageText={warningMessageText}
                        subtitleText={subtitleText}
                    />

                    <div className='successDialogButton'>
                        <DialogButton
                            className={`buttonGeneral ${isFailure ? 'cslFailureCloseButton' : 'cslSuccessCloseButton'}`}
                            onClick={handleAction(isFailure ? errorModalOnCloseClick : onCloseClick)}
                            testId="close"
                        >
                            Close
                        </DialogButton>

                        {isFailure && (
                            <DialogButton
                                className="buttonGeneral cslFailureTryAgainButton"
                                onClick={handleAction(onTryAgainClick)}
                                testId="try-again"
                            >
                                Try Again
                            </DialogButton>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default EmailInviteSuccessDialog;