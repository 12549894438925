const validateDate = (date)=>{
    let datePaid;
    if (date?.default) {
        const dateParts = date.default.split('/');
        if (dateParts.length === 3) {
            const year = dateParts[2];
            const month = dateParts[0];
            const day = dateParts[1];
            datePaid = `${year}-${month}-${day}`;
        } 
    } else {
        datePaid = date?.default || '';
    }

    const maxDate = `${date.year + 1}-12-31`;
    const minDate = `${date.year}-01-01`;

    // validate Arrival Date
    if(date?.default){
        if(datePaid < minDate || (datePaid > maxDate)){
            date.error=true;
            date.errorMessage = `Year can only be current year ${date.year} or future year ${date.year + 1}`
        }
        else{
            date.error=false;
            date.errorMessage = ""
        }
    }   
}

export {
    validateDate
}