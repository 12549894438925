import React, { useEffect, useState } from 'react';
import { useToggle } from '@react-md/utils';
import moment from 'moment-timezone';
import {
    Dialog, DialogHeader, DialogContent, DialogFooter,
} from "@react-md/dialog";
import {
    Button, Grid, GridCell, Divider, Checkbox
} from 'react-md';

import {
    NOT_SENT, CLOSE, EMAIL_CHECKLIST_SENT_SUCCESS_TEXT,
    EMAIL_CHECKLIST_SENT_FAILED_TEXT, CANCEL, SEND
} from '@utilities/constants/strings';

import { handleEmailChecklistClick, getChecklistEmailRecipients } from '@utilities/services/checklistService';

import './EmailChecklistDialog.css'
import GridCellEmailTooltip from '@components/gridCellEmailTooltip';

function EmailChecklistDialog(props) {
    const { organizerId, disableEmailChecklistDialog } = props;
    const [userList, setUserList] = useState();
    const [resultText, setResultText] = useState('');

    const [showResultDialog, enableResultDialog, disableResultDialog] = useToggle(false);

    const HAS_SELECTED_USERS = userList && userList.filter(x => x.isSelected).length > 0;

    const handleResultDialogClose = async () => {
        disableResultDialog();
        if (resultText === EMAIL_CHECKLIST_SENT_SUCCESS_TEXT) {
            disableEmailChecklistDialog();
        }
    }

    const getUserList = async () => {
        const userData = [];
        await getChecklistEmailRecipients(organizerId)
            .then((users) =>
                userData.push(...users.map(user => {
                    const checklistLastSent = user.checklistLastSent
                        ? moment(new Date(user.checklistLastSent))
                            .tz('America/Chicago').format('MM/DD/YYYY - hh:mm A [CT]')
                        : NOT_SENT
                    const checklistSentBy = user.checklistSentBy ?? NOT_SENT;
                    return {
                        ...user,
                        name: user.displayName,
                        checklistLastSent,
                        checklistSentBy,
                        isSelected: false
                    }
                }))
            );
        setUserList(userData);
    }

    const handleSendChecklistEmailClick = async () => {
        if (HAS_SELECTED_USERS) {
            const recipientIds = userList.filter(x => x.isSelected).map(x => x.id);
            const result = await handleEmailChecklistClick(organizerId, recipientIds);
            if (result) {
                await getUserList();
            }
            setResultText(result ? EMAIL_CHECKLIST_SENT_SUCCESS_TEXT : EMAIL_CHECKLIST_SENT_FAILED_TEXT);

            enableResultDialog();
        }
    }

    const handleRowCheckboxClick = (event) => {
        setUserList(prevData => {
            return prevData.map(x => {
                if (x.id === event.target.value) {
                    x.isSelected = event.target.checked;
                }
                return x;
            })
        })
    }

    const renderRowGridCell = (user, propertyName, index, colSpan, customClass) => {
        const isNotSentTextStyle = user[propertyName] === NOT_SENT ? 'not-sent-text' : '';
        return (
            <GridCell
                colSpan={colSpan}
                className={`email-checklist-cell ${customClass} ${isNotSentTextStyle}`}
                data-testid={`email-checklist-${propertyName}-${index}`}
            >
                {user[propertyName]}
            </GridCell>
        )
    }

    const renderHeaderGridCell = (colSpan, cellText, customClass) => {
        return (
            <GridCell
                colSpan={colSpan}
                className={`email-checklist-cell-header ${customClass}`}
            >
                {cellText}
            </GridCell>
        );
    }

    useEffect(() => {
        getUserList();
    }, [organizerId]);

    return (
        <>
            <Dialog
                id="result-dialog"
                role="alertdialog"
                visible={showResultDialog}
                onRequestClose={() => { }}
                className={'result-dialog'}
                aria-labelledby="result-dialog"
                data-testid="result-dialog">
                <DialogHeader></DialogHeader>
                <DialogContent className={`result-dialog-content`}>
                    {resultText}
                    <Button
                        id="dialog-closebtn"
                        className={`email-checklist-send-button`}
                        style={{ marginTop: '30px' }}
                        onClick={handleResultDialogClose}
                    >
                        {CLOSE}
                    </Button>
                </DialogContent>
            </Dialog>
            <Dialog
                id="email-checklist-dialog"
                role="alertdialog"
                visible={true}
                onRequestClose={() => { }}
                className={'email-checklist-dialog'}
                aria-labelledby="email-checklist-dialog-title"
                data-testid="email-checklist-dialog-title">
                <DialogHeader className={'email-checklist-dialog-header'}>
                    <div>
                        <p className={'email-checklist-header-text'}>Send Checklist</p>
                        <p className={'email-checklist-sub-header-text'}>Select user(s) to send checklist</p>
                    </div>
                </DialogHeader>
                <DialogContent className={'email-checklist-dialog-body'}>
                    <Grid className='email-checklist-header-container'>
                        {renderHeaderGridCell(1, '')}
                        {renderHeaderGridCell(2, 'Send To', 'grid-name-cell')}
                        {renderHeaderGridCell(3, 'Email Address')}
                        {renderHeaderGridCell(2, 'Role')}
                        {renderHeaderGridCell(2, 'Last Sent')}
                        {renderHeaderGridCell(2, 'Sent By')}
                    </Grid>
                    {
                        userList
                            ? userList.map((user, index) => (
                                <Grid key={`user-row-${index}`} className='email-checklist-grid-container' data-testid={`email-checklist-grid-container`}>
                                    <GridCell colSpan={1}>
                                        <Checkbox
                                            data-testid={`email-checklist-checkbox-${index}`}
                                            id={`email-checklist-checkbox-${index}`}
                                            key={`email-checklist-checkbox-${index}`}
                                            onChange={handleRowCheckboxClick}
                                            value={user.id}
                                            checked={user.isSelected}
                                        />
                                    </GridCell>
                                    {renderRowGridCell(user, 'name', index, 2, 'grid-name-cell')}
                                    {<GridCellEmailTooltip {...{ user, propertyName: 'email', index, colSpan: 3 }} />}
                                    {renderRowGridCell(user, 'role', index, 2, 'grid-role-cell')}
                                    {renderRowGridCell(user, 'checklistLastSent', index, 2, 'grid-lastsent-cell')}
                                    {renderRowGridCell(user, 'checklistSentBy', index, 2, 'grid-sentby-cell')}
                                    <GridCell colSpan={12} className={'email-checklist-gridCell-padding'}>
                                        <Divider className={'email-checklist-grid-divider'} />
                                    </GridCell>
                                </Grid>
                            ))
                            : (<div style={{
                                display: 'flex', width: '100%',
                                justifyContent: 'center'
                            }}>
                                Loading...
                            </div>)
                    }
                </DialogContent>
                <DialogFooter className={'email-checklist-dialog-footer'}>
                    <div>
                        <Button
                            id="dialog-cancelbtn"
                            className='email-checklist-cancel-button-active'
                            onClick={disableEmailChecklistDialog}
                        >
                            {CANCEL}
                        </Button>
                        <Button
                            id="dialog-sendbtn"
                            className={`email-checklist-send-button ${HAS_SELECTED_USERS ? '' : 'inactive'}`}
                            onClick={() => handleSendChecklistEmailClick()}
                        >
                            {SEND}
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default EmailChecklistDialog;