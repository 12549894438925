import * as CARDSTATUS from '@utilities/constants/cardStatus';
import getDashboard from '@utilities/constants/dashboards';

function handleSharedEntityDialog(dispatch, ACTION, formNameKey, entitiesList, updateStatusCard, year)  {
    const { dashboard } = getDashboard(year.current)
    const updatedSharedEntityMessage = 'Updating this field may affect  other “Completed” forms and will revert them to the “In Progress” status.  Please review any forms affected by this change.';
    
    entitiesList.forEach((entity) => {
        if (entity?.formNameKey === formNameKey) {
            if (entity?.parentEntityKeys?.length > 1) {
                dispatch(ACTION.setShowCustomDialog(true));
                dispatch(ACTION.setCustomDialogTitle(''));
                dispatch(ACTION.setCustomDialogMsg(updatedSharedEntityMessage)); 
            }
            entity.parentEntityKeys.forEach((entityKey) => {
                const cardKey = entityKey.parentFormName.split('-');
                dashboard?.forEach((cat) => {
                    cat?.cards?.forEach((card) => {
                        if (card?.formName === cardKey[0] && card?.statusId === CARDSTATUS.COMPLETED) {
                            updateStatusCard(CARDSTATUS.IN_PROGRESS, card);
                        } 
                    });
                });
            });
        }
    });
};

export {
    handleSharedEntityDialog
};