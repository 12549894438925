import { GridCell } from 'react-md';
import styles from './GridCellEmptyList.module.css';

const GridCellEmptyList = () => {
    return (
        <GridCell className={styles.gridCellEmptyList} colSpan={12}>
            <p>There is no email associated with this client.<br />
                Please verify users who should be related to this organizer.</p>
        </GridCell>
    );
};

export default GridCellEmptyList;