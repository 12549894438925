import React, { useEffect } from 'react';
import * as IMG from '@utilities/constants/images';
import useAdaptive from "@utilities/hooks/useAdaptive";

import './error.css';

function RequestError() {

    const {
        isLaptopOrDesktop,
        isTablet,
        isMobile,
        isMobile480,
        isMobileDevice
    } = useAdaptive();

    const [pageStyle, setPageStyle] = React.useState("");

    useEffect(() => {

        // call API to trigger creation of support ticket for client error (different story)
        // what param to send to identify the client?
        // CRITICAL: ENVIRONMENT VARIABLE FOR API THAT CREATES THE SUPPORT TICKET, AUTHENTICATION SETUP ALSO.

        // console.log(`isLaptopOrDesktop`, isLaptopOrDesktop);
        // console.log(`isMobileDevice`, isMobileDevice);

        if (isTablet) {
            setPageStyle("tablet-view");
        } else if (isMobileDevice || isMobile || isMobile480) {
            setPageStyle("mobile-view");
        } else {
            setPageStyle("");
        }
    }, [isTablet, isMobileDevice, isMobile, isMobile480]);

    return (
        <>
            <div className="dashboardSize" style={{ minHeight: '65vh' }}>
                <div className={`error-page ${pageStyle}`}>
                    <div className={`error-title ${pageStyle}`}>
                        <img className="error-icon" alt="error_icon" src={IMG.ERROR_ICON} />
                        Error: Your Request Cannot Be Completed
                    </div>
                    <div className={`error-body ${pageStyle}`}>
                        Please exit the application and try again. For additional questions, please reach out to your local CLA tax professional.
                    </div>
                    <div className={`error-footer ${pageStyle}`}>
                        Thank you for your patience.
                    </div>
                </div>
            </div >
        </>
    );
}

export default RequestError;
