/*
/App

The main entry point for application components.
*/

import './App.scss';
import './App.css';
// common modules
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import _ from 'lodash';
import { useIntercom } from 'react-use-intercom';

// custom modules
import { reactPlugin, appInsights } from '@utilities/applicationInsights.js';
import usePageFramework, {
  currentYear,
} from '@utilities/hooks/usePageFramework';
import { getRoutes } from '@utilities/routes';
import TopBar from '@components/header/topBar';
import HeaderMenu from '@components/header/headerMenu';
import HeaderMenuMobile from '@components/header/headerMenu/mobile';
import HeaderImageRenderer from '@components/header/headerImage/headerImageRenderer';
import AppFooter from '@components/footer';
import ProgressDialog from '@components/dialog/progressDialog';
import CustomDialog from '@components/dialog/customDialog';

import api, { initRoles, hasRole, scopes, getEmail } from '@utilities/claApi';
import {
  authenticated,
  getAccount,
  injectLocalDevelopmentTokens,
} from '@utilities/authentication';
import lastUserActivityDate, {
  lastUserActivityDateSetter,
} from '@utilities/helpers/lastUserActivityDate';
import { LicenseInfo } from '@mui/x-license-pro';
import UploadWarningDialog from '@components/dialog/uploadWarningDialog';
import ShowLastFormSave from '@components/showLastFormSave';
import AppFooterMobile from '@components/footer/mobile';
import {
  SCREEN_BREAKPOINTS,
  SCREEN_SIZES,
} from '@utilities/constants/screenSizes';
import useAdaptive from '@utilities/hooks/useAdaptive';
import getDashboard from '@utilities/constants/dashboards';
import clientHqTodoApi from '@utilities/clientHqTodoApi';

import { setRequiredForms } from '@utilities/helpers/setRequiredForms';
import getYear from '@utilities/helpers/getYear';


LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY);

//redirect to AAD login for sso, call when unauthenticated
const RedirectToLogin = () => {
  const { instance, inProgress } = useMsal();

  // when interaction is complete, redirect to login
  if (inProgress === InteractionStatus.None) {
    if (
      !process.env.NODE_ENV ||
      (process.env.NODE_ENV === 'development' &&
        !process.env.REACT_APP_DANGER_BYPASS_INJECT_LOCAL_TOKENS)
    ) {
      // inject development tokens into cache then refresh entire page
      injectLocalDevelopmentTokens();
      window.location.reload();
    } else {
      // redirect to MSAL login
      instance.loginRedirect({ scopes });
    }
  }

  return null;
};

function App() {
  const { selectState, REDUX, dispatch, ACTION, history, NAV } = usePageFramework();
  const { boot } = useIntercom();

  // Roles
  const isAuthenicated = selectState(REDUX.IS_AUTHENTICATED);
  const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
  const isAdmin = selectState(REDUX.IS_ADMIN);
  const isClient = selectState(REDUX.IS_CLIENT);

  const activeReturn = selectState(REDUX.ACTIVE_RETURN);
  const authorizedIds = selectState(REDUX.AUTHORIZED_IDS);

  // Progress Dialog
  const isProgressVisible = selectState(REDUX.PROGRESS_VISIBLE);
  const progressText = selectState(REDUX.PROGRESS_TEXT);

  // Custom Dialog
  const showCustomDialog = selectState(REDUX.SHOW_CUSTOM_DIALOG);

  //Upload Warning Dialog
  const isUploadWarningVisible = selectState(REDUX.UPLOAD_WARNING_VISIBLE);
  const dupFiles = selectState(REDUX.DUPLICATE_UPLOAD_FILES) || [];
  const uploadProps = selectState(REDUX.UPLOADS_PROPS);
  const uploadList = selectState(REDUX.UPLOAD_LIST);
  const { isDesktopDevice, isLaptopOrDesktop } = useAdaptive();
  const year = getYear(selectState);
  const dashboardObj = getDashboard(year.current);

  const availableRoutes = getRoutes(
    isAuthenicated,
    isPractitioner,
    isAdmin,
    isClient,
    activeReturn,
    isDesktopDevice,
    isLaptopOrDesktop,
    authorizedIds
  ).reduce((prev, page) => {
    prev.push(
      <Route
        path={page.to}
        exact
        component={page.componentObject}
        key={page.to}
      />
    );
    return prev;
  }, []);

  lastUserActivityDateSetter.lastUserActivityDate =
    lastUserActivityDate(selectState);

  useEffect(() => {
    if (isClient) {
      if (!window.location.pathname.includes('chq-checklist-download')) {
        history.push('/');
      }
    }
  }, [isClient, history]);

  // GROWTH: Handle loading data with multiple client numbers
  useEffect(() => {
    async function initialize() {
      let isAdmin = false;
      let isPractitioner = false;
      let isClient = false;
      let email = '';

      // if authenticated, load app
      if (authenticated()) {
        const account = getAccount();

        // GROWTH: Would this be more appropriate to have following sign in?
        if (account) {
          // set Authenticated User Context for AppInsights
          appInsights.setAuthenticatedUserContext(
            account.username ? account.username : account.idTokenClaims.email
          );

          // boot intercom as current user
          boot({
            name: account.name,
            email: account.username
              ? account.username
              : account.idTokenClaims.email,
            hideDefaultLauncher: true,
          });
        }

        try {
          await initRoles();

          // determine user roles and store in state
          isAdmin = hasRole(['Administrator']) ?? false;
          isPractitioner =
            (!hasRole(['Administrator']) && hasRole(['Employee'])) ?? false;
          isClient =
            (!hasRole(['Administrator']) &&
              !hasRole(['Employee']) &&
              hasRole(['Client'])) ??
            false;
          email = getEmail();
          // TODO Growth: we can combine these actions into a single action. Something like setUserRoles. Combining the actions (and therefore lessening the number of them) will result in better self-documenting redux reducers.
          dispatch(ACTION.setIsAdmin(isAdmin));
          dispatch(ACTION.setIsPractitioner(isPractitioner));
          dispatch(ACTION.setIsClient(isClient));
          dispatch(ACTION.setAuthUserEmail(email));

          const limit = 50;
          if (isClient) {
            // TODO Growth: since we know we want this to be false initially, we can initialize the reducer state as 'false' and no longer need to dispatch this action (self documentation)
            // when client first login, set false, so modal will pop
            if (window.location.pathname.includes('chq-checklist-download')) {
              dispatch(ACTION.setHasSkipEntryStep(true));
              return;
            } else {
              dispatch(ACTION.setHasSkipEntryStep(false));
            }
            // load organizers
            const {
              data: { results: organizers },
            } = await api.get(
              `/organizers?filter=year eq ${currentYear}&limit=${limit}`
            ).catch((err) => {
              history.push(`${NAV.ACCESS_TO_CLIENT_ERROR}`);
            });

            if (organizers.length === 0) {
              history.push(`${NAV.ACCESS_TO_CLIENT_ERROR}`);
            } else {
              dispatch(ACTION.setClientOrganizers(organizers));

              try {
                const response = await clientHqTodoApi.getTodos();
                const total = response.data.total;
                dispatch(ACTION.setClientHqTodos(total));
              } catch (error) {
                console.error('Error getting client hq todos', error.message);
              }

              const hasDifferentClients =
                new Set(organizers.map((organizer) => organizer?.client?.id))
                  .size > 1;
              let isPriorYearDataError = false;
              if (!hasDifferentClients) {
                const organizer = organizers[0];
                const id = organizer.id;
                const priorYearResponse = await api.get(`/organizers/${id}/prior`)
                  .catch((err) => {
                    if (err.response?.status !== 404) {
                      isPriorYearDataError = true;
                    } else console.log('No prior year data found')
                  });


                // what would be the impact of not calling the dispatches below?
                if (isPriorYearDataError) {
                  history.push(`${NAV.REQUEST_ERROR}`);
                }
                else {
                  const taxPayerName = organizer?.taxpayer?.displayName;
                  const taxpayerSpouseName = organizer?.spouse?.displayNamea;
                  const priorYearReturnId = organizer?.priorYearReturnId;
                  const isTermsAgreed = organizer?.isTermsAgreed;

                  dispatch(ACTION.setOrganizerId(id));
                  const result = await api.get(`/organizers/${id}/dashboard`);
                  const priorYearData = priorYearResponse?.data?.data?.taxData?.priorYear || null;
                  const dashboard = result?.data?.dashboard;
                  const dashCopy = setRequiredForms(priorYearData || [], _.cloneDeep(dashboard || dashboardObj.dashboard));
                  const currentDashboard = _.cloneDeep(dashCopy);
                  dispatch(ACTION.setDashboard(currentDashboard || dashboardObj.dashboard));
                  dispatch(ACTION.setDashboardId(result?.data?.id));
                  dispatch(ACTION.setTaxpayerName(taxPayerName));
                  dispatch(ACTION.setpriorYearReturnId(priorYearReturnId));
                  dispatch(ACTION.setTaxPayerSpouseName(taxpayerSpouseName));
                  dispatch(ACTION.setOrganizerId(id));
                  dispatch(ACTION.setSelectedOrganizer(organizer));
                  dispatch(ACTION.setIsTermsAgreed(isTermsAgreed));
                  dispatch(
                    ACTION.setActiveReturn({
                      displayName: organizer?.client?.name,
                      clientNumber: organizer?.client?.number,
                      currentYear: organizer?.year,
                      formStatus: organizer?.status,
                    })
                  );
                }
              }

              if (!isPriorYearDataError) {
                dispatch(ACTION.setHasMultipleReturns(hasDifferentClients));
                const authorizedIds = organizers.map((org) => org.id);
                dispatch(ACTION.setAuthorizedIds(authorizedIds));
              }
            }
          }

          if (isAdmin || isPractitioner) {
            // Load locking permissions for the Exchange Manager
            try {
              const {
                data: { userLockPermission },
              } = await api.get('/users/me');
              dispatch(
                ACTION.setLockingPermission(userLockPermission || false)
              );
            } catch (error) {
              // Do not throw an error if the internal client record does not exist in the system.
              // GROWTH: add a user record when the user gains access to web-1040, or upsert the user record on login
              if (error.response?.status === 404) {
                dispatch(ACTION.setLockingPermission(false));
                return;
              }

              throw error;
            }
          }
        } catch (err) {
          if (!isAdmin && !isPractitioner) {
            console.error('Contact admin to get set up');
          }
          console.error('Error getting the taxpayer data', err.message);
        } finally {
          dispatch(ACTION.setisAuthenticated(authenticated() ?? false));
        }
      }
    }

    const handleWindowResize = _.debounce(() => {
      const screenWidth = window.innerWidth;

      if (
        screenWidth >= SCREEN_BREAKPOINTS.MOBILE_480 &&
        screenWidth < SCREEN_BREAKPOINTS.TABLET
      ) {
        dispatch(ACTION.setScreenSize(SCREEN_SIZES.MOBILE_480));
        return;
      }

      if (screenWidth < SCREEN_BREAKPOINTS.TABLET) {
        dispatch(ACTION.setScreenSize(SCREEN_SIZES.MOBILE));
        return;
      }

      if (
        screenWidth >= SCREEN_BREAKPOINTS.TABLET &&
        screenWidth < SCREEN_BREAKPOINTS.LAPTOP
      ) {
        dispatch(ACTION.setScreenSize(SCREEN_SIZES.TABLET));
        return;
      }

      if (
        screenWidth >= SCREEN_BREAKPOINTS.LAPTOP &&
        screenWidth < SCREEN_BREAKPOINTS.DESKTOP
      ) {
        dispatch(ACTION.setScreenSize(SCREEN_SIZES.LAPTOP));
        return;
      }

      dispatch(ACTION.setScreenSize(SCREEN_SIZES.DESKTOP));
    }, 300);

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    initialize();

    return () => window.removeEventListener('resize', handleWindowResize);
    //eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      {isDesktopDevice && isLaptopOrDesktop ? (
        <>
          <TopBar />
          <HeaderMenu />
        </>
      ) : (
        <HeaderMenuMobile />
      )}
      <HeaderImageRenderer />
      <>
        <AuthenticatedTemplate>
          <Switch>{availableRoutes}</Switch>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <RedirectToLogin />
        </UnauthenticatedTemplate>
        <ShowLastFormSave className="lastSaveMsgFt" isNavBar={true} />
      </>
      {!isLaptopOrDesktop ? <AppFooterMobile /> : <AppFooter />}
      <ProgressDialog
        visible={isProgressVisible ?? false}
        loadingText={progressText ?? ''}
      />
      <CustomDialog visible={showCustomDialog || false} />
      <UploadWarningDialog
        visible={isUploadWarningVisible || false}
        dupFiles={dupFiles}
        uploadProps={uploadProps}
        uploadList={uploadList}
      />
    </div>
  );
}

export default withAITracking(reactPlugin, App);
