import PropTypes from 'prop-types';
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import { DropdownMenu } from 'react-md';

const CustomToolbar = ({ setFilterButtonEl, filterHighlight }) => {

  return (
    <GridToolbarContainer data-testid='custom-toolbar-container' style={{ display: 'flex' }}>
      <GridToolbarFilterButton
        data-testid='custom-toolbar-button'
        ref={setFilterButtonEl}
        componentsProps={{
          button: {
            startIcon: (
              <FilterAltIcon className={filterHighlight} data-testid='custom-toolbar-icon' />
            )
          }
        }}
      />

      <DropdownMenu
        id='custom-toolbar-kabob'
        data-testid='custom-toolbar-kabob'
        buttonType="icon"
        buttonChildren={<MoreVertSVGIcon className="dropDownMenu moreIconBtn" />}
        style={{
          opacity: '0%',
          color: '#ffffff',
        }}
      >
      </DropdownMenu>
    </GridToolbarContainer>

  );
};

CustomToolbar.propTypes = {
  setFilterButtonEl: PropTypes.func.isRequired,
};

export default CustomToolbar;
