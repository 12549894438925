// common modules
import React from 'react';

// custom modules
import RestfulTable from '@components/restfulTable/RestfulTable.js';
import * as NAV from '@utilities/constants/navigation';
import Breadcrumb from '@components/Breadcrumb.js';

function ManageGroups(props) {
    const resourceName = 'Group';
    const resourceUri = 'groups';
    const columns = [
        { key: 'name', text: 'Name', create: { show: true, required: true }, edit: { show: true, required: true } },
        { key: 'displayName', text: 'Display Name', create: { show: true, required: true, }, edit: { show: true, required: true } },
        { key: 'aadId', text: 'AAD ID', create: { show: true, required: true, }, edit: { show: true, required: true } }
    ];

    const defaultSortKey = 'displayName';
    const defaultSortOrder = 'ascending';

    const navItems = [
        { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
        { to: NAV.MANAGE_GROUPS, label: 'Groups', current: true }
    ];

    const filterFn = (searchValue) => (item) => {
        return item.name?.toLowerCase().includes(searchValue);
    };

    return (
        <div className="pracDashboardSize">
            <Breadcrumb items={navItems} />
            <h1 style={{
                'textAlign': 'left',
                'paddingLeft': '45px',
                'color': '#2e334e'
            }}>Groups</h1>
            <RestfulTable resourceName={resourceName} resourceUri={resourceUri} columns={columns} defaultSortKey={defaultSortKey} defaultSortOrder={defaultSortOrder} pageSize={5} filterFn={filterFn}></RestfulTable>
        </div>
    );
}

export default ManageGroups;