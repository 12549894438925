import api from '@utilities/claApi';

const getClientList = async (organizerId) => {
    let organizerUsers = [];
    try {
        const response = await api.get(`/organizers/${organizerId}`);

        if (response.data?.taxpayer?.id) {
            const taxpayer = await api.get(`/users/${response.data.taxpayer.id}`);
            organizerUsers.push({
                ...taxpayer.data,
                role: response.data.taxpayer.role
            });
        }
        
        if (response.data?.spouse?.id) {
            const spouse = await api.get(`/users/${response.data.spouse.id}`);
            organizerUsers.push({
                ...spouse.data,
                role: response.data.spouse.role
            });
        }

        return organizerUsers;
    } catch (error) {
        console.error(error);
        return [];
    }
}

const updateNotificationSuppression = (suppressedUsers, suppressAllScheduledNotifications, cancelSource = null) => {
    try {
        return suppressedUsers.map(user => api.put(
            `/users/${user._id}`,
            {
                suppressAllScheduledNotifications,
                schedNotificationSuppressedBy: user.schedNotificationSuppressedBy
            },
            { cancelToken: cancelSource?.cancelToken || null }
        ));
    } catch(error) {
        console.error(error);
        return [Promise.reject(error)];
    }
}

export {
    getClientList,
    updateNotificationSuppression
}