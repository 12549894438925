import { useState, useEffect } from 'react';
import {
	Grid,
	GridCell,
	Divider,
} from 'react-md';
import _ from 'lodash';
import usePageFramework from '@utilities/hooks/usePageFramework';
import setDerivedOrganizerData from '@utilities/organizerData';
import * as STR from '@utilities/constants/strings';
import DashboardGridCell from './dashboardGridCell';
import TaxReturnSwimlane from './taxReturnSwimlane';
import useAdaptive from "@utilities/hooks/useAdaptive";

import './dashboardGrid.css';
import './dashboardGrid.scss';
import { taxReturnStatuses } from '@utilities/constants/taxReturn';
import DashboardViewButton from './DashboardViewButton';

function DashboardGrid(props) {
	const { categories, dashboardVersion } = props;
	const { ACTION, dispatch, REDUX, CARDSTATUS, selectState } = usePageFramework();
	const taxYear = selectState(REDUX.YEAR);
	const isClient = selectState(REDUX.IS_CLIENT);
	const organizerId = selectState(REDUX.ORGANIZER_ID);
	const filterStatusId = selectState(REDUX.DASHBOARD_FILTER_STATUS);
	const taxReturnStatus = selectState(REDUX.TAX_RETURN_STATUS);
	const selectedOrganizer = selectState(REDUX.SELECTED_ORGANIZER);
	const isProgressVisible = selectState(REDUX.PROGRESS_VISIBLE);
	const isEntryExperienceOpen = selectState(REDUX.ENTRY_MODAL_OPEN);

	const { isLaptopOrDesktop, isDesktopDevice } = useAdaptive();
	const [filteredCategories, setFilteredCategories] = useState([]);
	const cardsPerRow = 5;
	const sortReq = selectState(REDUX.DASHBOARD_SORT_REQ);
	const [isViewAll, setIsViewAll] = useState([
		{ id: 0, show: true },
		{ id: 1, show: true },
		{ id: 2, show: true },
		{ id: 3, show: true },
		{ id: 4, show: true },
	]);
	const [categoryIndex, setCategoryIndex] = useState();

	const [categoryEntryType, setCategoryEntryType] = useState('');
	const [isEqualLength, setIsEqualLength] = useState([
		{ id: 0, isEqual: false },
		{ id: 1, isEqual: false },
		{ id: 2, isEqual: false },
		{ id: 3, isEqual: false },
		{ id: 4, isEqual: false },
	]);

	useEffect(() => {
		const isTaxReturnStatusCollapseSwimlane = taxReturnStatus === taxReturnStatuses.signed || taxReturnStatus === taxReturnStatuses.partial;

		if (isTaxReturnStatusCollapseSwimlane) {
			setIsViewAll((prevState) => {
				return prevState.map(item => ({ ...item, show: false }));
			});
		}
	}, [taxReturnStatus]);

	const getSectionName = (obj) => {
		return obj.worksheet?.[0]?.split('\\').pop() || null;
	};

	const dashboardCategories = () => {
		const catCopy = _.cloneDeep(categories);

		const priorYearOrganizerCategorizer = (forms) => {
			const personalAndOtherInformation = {
				cards: [],
				entryType: 'Personal',
				title: 'Personal and Other Information'
			}
			const incomeInformation = {
				cards: [],
				entryType: 'Income',
				title: 'Income Information'
			}
			const deductionsAndCredits = {
				cards: [],
				entryType: 'Expenses',
				title: 'Deductions and Credits'
			}
			for (let i = 0; i < forms.length; i++) {
				forms[i].cards.map((card) => {
					if (card.key.startsWith('dashCard-1')) {
						personalAndOtherInformation.cards.push(card)
					} else if (card.key.startsWith('dashCard-2')) {
						incomeInformation.cards.push(card)
					} else if (card.key.startsWith('dashCard-3')) {
						deductionsAndCredits.cards.push(card)
					}
				})
			}
			const docManagerForms = [personalAndOtherInformation, incomeInformation, deductionsAndCredits];
			return docManagerForms
		}

		const priorYearOrganizerCatCopy = priorYearOrganizerCategorizer(catCopy)

		if (dashboardVersion !== 2) {
			if (filterStatusId !== CARDSTATUS.REQUIRED && priorYearOrganizerCatCopy.length) {
				const optionalFormsSection = {
					title: 'Optional Forms (Unless Applicable)',
					entryType: 'optionalForms',
					cards: []
				};
				priorYearOrganizerCatCopy.forEach(category => {
					if (category.entryType !== 'optionalForms') {
						category.cards.forEach(card => {
							if (!card.isRequired && !card.hasPriorData) {
								optionalFormsSection.cards.push(card);
							}
							if (card.hasPriorData) {
								card.isRequired = true;
							}
						});
					}
				});
				priorYearOrganizerCatCopy.push(optionalFormsSection);
			}

			priorYearOrganizerCatCopy.forEach(category => {
				if (category.entryType !== 'optionalForms')
					category.cards = category.cards.filter(card => card.isRequired || card.hasPriorData);
			});
			return priorYearOrganizerCatCopy;

		} else if (dashboardVersion === 2) {
			if (!catCopy[0]) catCopy[0] = { title: 'Required Information', entryType: 'Personal', cards: [] };
			if (!catCopy[1]) catCopy[1] = { title: 'Recommended Information', entryType: 'Income', cards: [] };

			const requiredCategory = catCopy.find(category => category.entryType === 'Personal');
			const recommendedCategory = catCopy.find(category => category.entryType === 'Income');
			const optionalCategory = { title: 'Optional Information', entryType: 'optionalForms', cards: [] };

			catCopy.forEach(category => {
				category.cards.forEach(card => {
					const sectionName = getSectionName(card);

					// Check if card should be in Required or Recommended based on hasPriorData and worksheet
					if (sectionName && ['Sch C - Business', 'Sch E, p 1 - Rent and Royalty', 'Sch F / 4835 - Farm'].includes(sectionName)) {
						if (card.isRequired === true) {
							requiredCategory.cards.push(card);
						} else if (card.hasPriorData) {
							card.isRequired = true;
							requiredCategory.cards.push(card);
						}
					} else if (!card.isRequired && (card.hasPriorData || card.isRecommended)) {
						// Push to Recommended
						card.isRequired = false;
						card.isRecommended = true;
						const cardIndex = _.findIndex(recommendedCategory.cards, { key: card.key });
						if (cardIndex === -1) {
							recommendedCategory.cards.push(card);
						}
					}

					if (sectionName && card.title === STR.FOREIGN_ASSETS && card.isRecommended === true) {
						const cardIndex = _.findIndex(recommendedCategory.cards, { key: card.key });
						if (cardIndex === -1) {
							recommendedCategory.cards.push(card);
						}
					}

					if (!card.isRequired && !card.isRecommended && !card.hasPriorData) { // For other cards, push to Optional if it doesn't have prior data
						card.isRequired = false;
						card.isRecommended = false;
						optionalCategory.cards.push(card);
					}
				});
			});

			catCopy[0].cards = catCopy.length && catCopy[0].cards.filter(card => card.isRequired);
			catCopy[1].cards = catCopy.length && catCopy[1].cards.filter(card => card.isRecommended);
			catCopy.push(optionalCategory);
			return catCopy;
		}
	};

	// const cardsPerRow = 5; //added const for cards per row
	const sortByTitleAToZ = (cardA, cardB) => cardA.title.toLowerCase().localeCompare(cardB.title.toLowerCase());
	const sortByTitleZToA = (cardA, cardB) => cardB.title.toLowerCase().localeCompare(cardA.title.toLowerCase());
	const sortByStatusIdIncreasing = (cardA, cardB) => cardA.statusId - cardB.statusId;
	const sortByStatusIdDecreasing = (cardA, cardB) => cardB.statusId - cardA.statusId;
	const sortByTraditional = (cardA, cardB) => cardA.key.localeCompare(cardB.key);
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);


	let filteredBy = '';
	switch (filterStatusId) {
		case CARDSTATUS.NOT_STARTED:
			filteredBy = 'Not Started';
			break;
		case CARDSTATUS.IN_PROGRESS:
			filteredBy = 'In Progress';
			break;
		case CARDSTATUS.ERRORS:
			filteredBy = 'Errors Only';
			break;
		case CARDSTATUS.COMPLETED:
			filteredBy = 'Completed';
			break;
		case CARDSTATUS.DOES_NOT_APPLY:
			filteredBy = 'Does Not Apply';
			break;
		case CARDSTATUS.REQUIRED:
			filteredBy = 'Required';
			break;
		case CARDSTATUS.RECOMMENDED:
			filteredBy = 'Recommended';
		default:
			filteredBy = '';
			break;
	}

	let sortBy = sortByTraditional;
	switch (sortReq) {
		case 'alphaIncreasing':
			sortBy = sortByTitleAToZ;
			break;
		case 'alphaDecreasing':
			sortBy = sortByTitleZToA;
			break;
		case 'progressIncreasing':
			sortBy = sortByStatusIdIncreasing;
			break;
		case 'progressDecreasing':
			sortBy = sortByStatusIdDecreasing;
			break;
		case 'traditional':
			sortBy = sortByTraditional;
			break;
		default:
			sortBy = sortByTraditional;
			break;
	}

	useEffect(() => {
		if (categoryEntryType) {
			if (isViewAll[categoryIndex].show) {
				setCategoryEntryType(prevState => {
					if ((isViewAll.show && prevState === categoryEntryType) || (prevState === categoryEntryType)) {
						setFilteredCategories(prevState => {
							const category = prevState.find(data => data.title === categoryEntryType);
							const index = prevState.indexOf(category);
							prevState[index].cards = dashboardCategories()
								.find(data => data.title === categoryEntryType).cards;
							return [...prevState];
						});
					}
				});
			} else if ((!isViewAll[categoryIndex].show && !filterStatusId) || (filterStatusId < 0)) {
				setFilteredCategories(prevState => {
					const category = prevState.find(data => data.title === categoryEntryType);
					const index = prevState.indexOf(category);
					prevState[index].cards = dashboardCategories()
						.find(data => data.title === categoryEntryType).cards
						.slice(0, cardsPerRow);
					return [...prevState];
				});
			} else if (!isViewAll[categoryIndex].show && filterStatusId) {
				setFilteredCategories(prevState => {
					const category = prevState.find(data => data.title === categoryEntryType);
					const index = prevState.indexOf(category);
					// Filter by statusId if filterStatusId > 0
					prevState[index].cards = dashboardCategories()
						.find(data => data.title === categoryEntryType).cards
						.filter(card => card.statusId === filterStatusId)
						.slice(0, cardsPerRow);
					return [...prevState];
				});
			}
		}
		// eslint-disable-next-line
	}, [categoryEntryType, isViewAll, setFilteredCategories, setCategoryEntryType, setIsViewAll, categories]);

	useEffect(() => {
		if (filterStatusId && filterStatusId !== -1) {
			const initialCategories = dashboardCategories().map((category, index) => {
				const filteredCards = filterStatusId === CARDSTATUS.REQUIRED ? category.cards.filter(card => card.isRequired)
					: filterStatusId === CARDSTATUS.RECOMMENDED ? category.cards.filter(card => card.isRecommended) : category.cards.filter(card => card.statusId === filterStatusId);
				let payload = {
					title: category.title,
					entryType: category.entryType,
					cards: filteredCards,
					defaultCardLength: filteredCards.length
				};

				isEqual(payload, index);

				return payload;
			});
			setFilteredCategories(initialCategories);
			dispatch(ACTION.setNavBarCards(initialCategories));
		} else {
			let initialCategories = dashboardCategories().map((category, index) => {
				let payload = {
					title: category.title,
					entryType: category.entryType,
					// cards: category.cards.slice(0, cardsPerRow),
					cards: category.cards, // change for showing all the cards as default
					defaultCardLength: category.cards.length
				};

				isEqual(payload, index);
				if (!categoryIndex && !isViewAll[index].show && payload.cards.length >= cardsPerRow) {
					payload.cards = payload.cards.slice(0, cardsPerRow);
				}

				return payload;
			});

			if (isClient && !isDesktopDevice) {
				initialCategories = initialCategories.filter(category => category.title === STR.REQUIRED_INFORMATION);
			}
			setFilteredCategories(initialCategories);
			dispatch(ACTION.setNavBarCards(initialCategories));
		}
		// eslint-disable-next-line
	}, [filterStatusId, categories]);

	useEffect(() => {
		setDerivedOrganizerData(organizerId);
		// eslint-disable-next-line
	}, []);

	const isEqual = (payload, categoryIndex) => {
		setIsEqualLength(prevState => {
			const data = prevState.find(item => item.id === categoryIndex);
			const index = prevState.indexOf(data);
			// changing the condition to check if the length is equal to the cards per row const
			prevState[index].isEqual = payload.defaultCardLength === cardsPerRow;
			return [...prevState];
		});
	};

	const onViewAllClick = (title, categoryIndex) => {
		setCategoryEntryType(title);
		setCategoryIndex(categoryIndex);
		// toggle view all forms
		setIsViewAll(prevState => {
			const newState = _.cloneDeep(prevState);
			const isView = newState.find(item => item.id === categoryIndex);
			const index = newState.indexOf(isView);
			newState[index].show = !newState[index].show;
			return [...newState];
		});
	};

	const filteredCard = (category) => {
		let filteredCards = filterStatusId === CARDSTATUS.REQUIRED ? category.cards.filter(card => card.isRequired)
			: category.cards.filter(card => card.statusId === filterStatusId);

		if (sortReq) {
			filteredCards.sort(sortBy).filter(card => card.statusId === filterStatusId);
		}

		return (
			filteredCards.length === 0 ? <GridCell className="noShow" colSpan={4}>
				No {filteredBy} Cards to Show
			</GridCell> :
				filteredCards.map((card, index) => <DashboardGridCell card={card} index={index} isClient={isClient} />)
		);
	};

	const allCards = (category) => {
		let allCards = category.cards;

		if (sortReq) {
			allCards.sort(sortBy);
		}

		const cardsForClient = allCards.filter((card) => {
			return !card.shouldRenderForPractioner;
		});
		const cards = isPractitioner ? allCards : cardsForClient;

		return cards.map((card, index) => <DashboardGridCell card={card} index={index} isClient={isClient} />);
	};

	const getEntryTypeText = (entryType) => {
		if (entryType === 'optionalForms') {
			return ' Optional';
		} else if (entryType === 'Income') {
			return ' Recommended';
		} else {
			return ' Required';
		}
	}

	const shouldShowTaxReturn = !isEntryExperienceOpen && !isProgressVisible;

	return (
		<Grid className="dashboardHeader">
			{
				shouldShowTaxReturn && <TaxReturnSwimlane
					isDesktop={isLaptopOrDesktop}
					isAdmin={!isClient}
					isDesktopDevice={isDesktopDevice}
					dashboardVersion={dashboardVersion}
					taxReturnStatus={selectedOrganizer ? selectedOrganizer.taxReturnStatus : taxReturnStatus}
					taxYear={taxYear}
					cardsPerRow={cardsPerRow}
					organizerId={organizerId}
				/>
			}
			{
				filteredCategories.map((category, index) => {
					const { defaultCardLength, entryType, cards } = category;

					const formsText = defaultCardLength === 1 ? 'Card' : 'Cards';
					const entryTypeText = getEntryTypeText(entryType);
					const isAdminOrDesktop = isLaptopOrDesktop || !isClient || isDesktopDevice;
					const headerTitleEntryText = isAdminOrDesktop ? `${defaultCardLength} ${formsText}${entryTypeText}` : '';

					return (
						<GridCell key={`category-${index}`} colSpan={12} className="categoryCell">
							<GridCell key={`categoryTitle-${index}`} colSpan={12} className="dashboardCategoriesHeader">
								<div className="dashboardCardHeaderTitle">
									<h1 className="dashHeaderTitle">
										{category.title} <span>{headerTitleEntryText}</span>
									</h1>
								</div>
								{isAdminOrDesktop && cards.length >= cardsPerRow &&
									<div className="viewAllBtn">
										<DashboardViewButton
											categoryTitle={category.title}
											index={index}
											onViewAllClick={onViewAllClick}
											showViewAll={isViewAll[index].show}
										/>
									</div>}
							</GridCell>
							<GridCell colSpan={12} id="dashboardCategoryDivider">
								<Divider />
							</GridCell>
							<Grid className="dashboardGridListCategory">
								{!filterStatusId || filterStatusId === -1 ? allCards(category) : filteredCard(category)}
							</Grid>
						</GridCell>
					)
				})
			}
		</Grid>
	);
}

export default DashboardGrid;