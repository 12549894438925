import moment from "moment";
import { MM_DD_YY } from '@utilities/constants/dateFormats';

// Build the 1040 custom display status based on the provided date
const deriveStatus = (organizer, defaultDate) => {
    let status = 'Not Started';
    const organizerDate = organizer.lastUserActivityOn || organizer.updatedOn || defaultDate;
    
    const formattedDate = moment(new Date(organizerDate)).format(MM_DD_YY);
    const dateString = organizerDate ? ` on ${formattedDate}` : '';

    if (organizer.locked) {
        status = `Locked by CLA${dateString}`;
    }
    else if (organizer.status === 'Completed') {
        status = `Completed${dateString}`;
    }
    else if (organizer.status === 'In Progress') {
        status = `Last Updated${dateString}`;
    }

    return status;
};

const deriveActivity = (organizer, defaultDate) => {
    const organizerDate = organizer.lastUserActivityOn || organizer.updatedOn || defaultDate;
    if (!organizerDate) {
        return 'N/A';
    }

    const dateString = moment(new Date(organizerDate)).format(MM_DD_YY);

    return organizer.status === 'In Progress' || organizer.status === 'Completed' || organizer.locked
        ? `${dateString}`
        : 'N/A';
};


export {
    deriveStatus,
    deriveActivity,
};