import React, { useState, useEffect, useRef } from 'react';
import { DataGridPro, gridClasses, getGridStringOperators } from '@mui/x-data-grid-pro';
import { Add } from '@mui/icons-material';
import { useToggle } from "@react-md/utils";
import { Stack } from '@mui/material';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import { DropdownMenu, MenuItem } from 'react-md';

import { MAX_PREFETCH_LIMIT, DEFAULT_FILTER_QUERY, DEFAULT_SORT_QUERY, OPERATORS_MAP, DEFAULT_PAGE_LIMIT, SORT_TO_DATA_MAP, FILTER_TO_DATA_MAP } from '@utilities/constants/restfulTable.js';
import useServerSideMUI from '@utilities/hooks/useServerSideMUI';
import api from '@utilities/claApi';
import CreateResourceDialog from '@components/restfulTable/CreateResourceDialog.js';
import DeleteResourceDialog from '@components/restfulTable/DeleteResourceDialog.js';
import EditResourceDialog from '@components/restfulTable/EditResourceDialog.js';
import usePageFramework from '@utilities/hooks/usePageFramework';
import CustomToolbar from '@components/restfulTable/customToolbar/index.js';
import * as RESTFULTABLE from '@utilities/constants/restfulTable.js';

function RestfulTable(props) {
	const {
		ACTION,
		dispatch,
	} = usePageFramework();
	const {
		resourceName,
		resourceUri,
		columns,
		disableCreate,
		actions,
		createOverRide,
		editOverRide,
		disableDelete,
		disableEdit,
	} = props;
	const [data, setData] = useState([]);
	const [prefetchedData, setPrefetchedData] = useState([]);

	const [isLoaded, enable, disable] = useToggle(false);
	const [paginatedLoad, setPaginatedLoad] = useState(false);
	const [totalResults, setTotalResults] = useState(0);
	const [showCreateDialog, enableCreateDialog, disableCreateDialog] = useToggle(false);
	const [showDeleteDialog, enableDeleteDialog, disableDeleteDialog] = useToggle(false);
	const [showEditDialog, enableEditDialog, disableEditDialog] = useToggle(false);
	const [selected, setSelected] = useState({});
	const previousQuery = useRef(null);
	const [filterButtonEl, setFilterButtonEl] = React.useState(null);

	const payload = {
		defaultFilter: DEFAULT_FILTER_QUERY,
		defaultSort: DEFAULT_SORT_QUERY,
		defaultPageSize: DEFAULT_PAGE_LIMIT,
		filterDataMap: FILTER_TO_DATA_MAP[resourceName.toLowerCase()],
		sortDataMap: SORT_TO_DATA_MAP[resourceName.toLowerCase()],
		operatorsMap: OPERATORS_MAP,
		defaultPrefetchSize: MAX_PREFETCH_LIMIT,
	};
	const {
		handleFilterModelChange,
		handleSortModelChange,
		handlePageModelChange,
		handlePageSizeChange,
		currentPageNumber,
		filterQueryParameters,
		filterMethodParameters,
		sortQueryParameters,
		pageSize,
		prefetchSize,
		currentFilterModel,
		currentSortModel,
	} = useServerSideMUI(payload);

	const filterHighlight = currentFilterModel?.items?.[0]?.value ? 'filterButtonHighlight' : 'filterButton';
	// build sort&filter query
	const buildQuery = (limit, offset, sorting, filterMethod, filtering) => {
		const filterQuery = filtering?.length ? filtering.map(x => `&filter=${x}`).join('') : '';
		const filterMethodQuery = filterMethod ? `&filterMethod=${filterMethod}` : '';
		const sortQuery = sorting && Object.entries(sorting).length ? Object.entries(sorting).map(([param, query]) => `&${param}=${query}`).join('') : '';
		return `${sortQuery}${filterMethodQuery}${filterQuery}&limit=${limit}&offset=${offset}`
	};

	useEffect(() => {
		setPaginatedLoad(true);
		const totalOffset = currentPageNumber * pageSize;
		const prefetchOffset = Math.floor(totalOffset / prefetchSize) * prefetchSize;
		const prefetchPageOffset = totalOffset % prefetchSize
		const query = buildQuery(prefetchSize, prefetchOffset, sortQueryParameters, filterMethodParameters, filterQueryParameters);
		// If the resulting queries are the same, grab prefetched data set
		if (previousQuery.current !== null && query === previousQuery.current) {
			const resultSlice = prefetchedData.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
			setData(resultSlice);
			setPaginatedLoad(false);
			enable();
			return;
		}
		previousQuery.current = query;
		api.get(`/${resourceUri}?${query}`).then((response) => {
			return response.data;

		}).then(response => {
			const { results, total } = response;
			const responseData = results || [];
			const resultSlice = responseData.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
			setPrefetchedData(responseData);
			setData(resultSlice);
			setTotalResults(total);
		}).finally(() => {
			enable();
			setPaginatedLoad(false);
		});
	}, [sortQueryParameters, filterMethodParameters, filterQueryParameters, pageSize, isLoaded, prefetchSize, currentPageNumber])


	const CustomNoResultsOverlay = () => (
		<Stack height="100%" alignItems="center" justifyContent="center">
			{`No ${resourceName} found...`}
		</Stack>
	);

	const createResource = async (resource) => {
		dispatch(ACTION.setProgressText(`Creating ${resourceName}...`));
		dispatch(ACTION.setProgressVisible(true));
		api.post(resourceUri, resource).then((response) => {
		}).catch((error) => {
			console.error(error);
			// Show error as dismissable dialog
			dispatch(ACTION.setCustomDialogTitle(`${error.response.data.message}`));
			dispatch(ACTION.setShowCustomDialog(true));
		}).finally(() => {
			// hide delete dialog
			disableCreateDialog();
			dispatch(ACTION.setProgressVisible(false));
			// update resource list
			disable();
		});
	};

	// update resource
	const updateResource = async (id, update) => {
		delete update.id;
		api.put(`${resourceUri}/${id}`, update).then((response) => {
			// hide create dialog
			disableEditDialog();

			// update resource list
			disable();
		}).catch((error) => {
			console.error(error);
		})
	};

	// delete resource
	const deleteResource = async (id) => {
		dispatch(ACTION.setProgressText(`Deleting ${resourceName}...`));
		dispatch(ACTION.setProgressVisible(true));
		api.delete(`${resourceUri}/${id}`).then((response) => {
		}).catch((error) => {
			console.error(error);
			// Show error as dismissable dialog
			dispatch(ACTION.setCustomDialogTitle(`${error.response.data.message}`));
			dispatch(ACTION.setShowCustomDialog(true));
		}).finally(() => {
			// hide delete dialog
			disableDeleteDialog();
			dispatch(ACTION.setProgressVisible(false));
			// update resource list
			disable();
		});
	};

	const getRowSpacing = React.useCallback((params) => {
		return {
			top: params.isFirstVisible ? 30 : 6,
			bottom: params.isLastVisible ? 9 : 6,
		};
	}, []);

	const totalColumns = columns.length + 1;
	const dynamicColumns = totalColumns < 5 ? (7 / totalColumns) : 1;

	const filterOperators = getGridStringOperators().filter(({ value }) =>
		RESTFULTABLE.OPERATORS_LIST.includes(value),
	);

	const columnsWithActions = [
		...columns.map(col => ({
			field: col.key,
			headerName: col.text,
			flex: dynamicColumns,
			filterOperators,
			filterable: col.key !== 'userLockPermission',
			minWidth: 200,
			maxWidth: 500,
			sortable: true
		})),
		{
			field: 'actions',
			type: 'actions',
			editable: false,
			canEdit: false,
			minWidth: 100,
			renderHeader: () => (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Add
						data-testid="create-button"
						onClick={() => {
							enableCreateDialog(true);
						}}
						style={{ cursor: 'pointer' }}
						disabled={disableCreate} />
				</div>
			),
			renderCell: (params) => (
				<>
					<DropdownMenu
						id='custom-toolbar-kabob'
						data-testid='custom-toolbar-kabob'
						buttonType="icon"
						buttonChildren={<MoreVertSVGIcon className="dropDownMenu moreIconBtn" />}
					>
						<MenuItem
							className='editMenuItem'
							data-testid='editMenuItem'
							disabled={disableEdit}
							onClick={() => {
								if (editOverRide) {
									editOverRide(params.row);
								} else {
									setSelected(params.row);
									enableEditDialog();
								}
							}
							}
						>
							Edit
						</MenuItem>
						<MenuItem
							className='deleteMenuItem'
							data-testid='deleteMenuItem'
							disabled={disableDelete}
							onClick={() => {
								setSelected(params.row);
								enableDeleteDialog();
							}}
						>
							Delete
						</MenuItem>
					</DropdownMenu>
				</>
			),
			flex: 1,
		}
	];
	return (
		<div style={{ height: 'auto', width: '100%' }}>
			<DataGridPro
				filterMode={'server'}
				rows={data}
				columns={columnsWithActions}
				loading={paginatedLoad}
				disableMultipleColumnsFiltering={false}
				filterModel={currentFilterModel}
				onFilterModelChange={(filterModel) => {
					handleFilterModelChange(filterModel);
					disable();
				}}
				getRowSpacing={getRowSpacing}
				filterDebounceMs={500}
				disableColumnResize={true}
				disableColumnSelector
				disableMultipleRowSelection={true}
				pageSize={pageSize}
				currentPageNumber={currentPageNumber}
				paginationMode={'server'}
				rowCount={totalResults}
				onPaginationModelChange={(props) => {
					handlePageSizeChange(props.pageSize);
					handlePageModelChange(props.page);
					disable();
				}}
				sortingMode={'server'}
				paginationModel={{ pageSize: pageSize, page: currentPageNumber }}
				sortModel={currentSortModel}
				onSortModelChange={(sortModel) => {
					handleSortModelChange(sortModel);
					disable();
				}}
				getDetailPanelHeight={({ row }) => 'auto'}
				localeText={{ toolbarFilters: "" }}
				pagination
				pageSizeOptions={[10, 25, 50]}
				autoHeight={true}
				rowHeight={98}
				disableVirtualization
				slots={{
					toolbar: CustomToolbar,
					noRowsOverlay: CustomNoResultsOverlay,
					noResultsOverlay: CustomNoResultsOverlay

				}}

				slotProps={{
					panel: {
						anchorEl: filterButtonEl,
					},
					toolbar: {
						setFilterButtonEl,
						filterHighlight,
					},
				}}
				sx={{
					[`& .MuiDataGrid-columnsPanel > div:first-child`]: { display: "none" },
					[`& .${gridClasses.row}`]: {
						bgcolor: '#ffffff',
						borderRadius: 1,
						margin: 2.3,
						paddingLeft: 4,
						width: '93%',
						border: 0.25,
						borderColor: '#E5E5E5',
						display: 'flex',
						justifyContent: 'space-between',
					},
					'& .MuiDataGrid-cell': {
						borderBottom: 'none',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					},
					'& .MuiDataGrid-cell[data-field="action"]': {
						display: 'flex',
						justifyContent: 'flex-end',
						marginRight: 15,
					},
					'& .MuiDataGrid-columnHeadersInner': {
						margin: 6.3,
					},
				}}
			/>
			<CreateResourceDialog visible={showCreateDialog} resourceName={resourceName} resourceProps={columns} onCancel={disableCreateDialog} onConfirm={createResource} />
			<EditResourceDialog visible={showEditDialog} selected={selected} resourceName={resourceName} resourceProps={columns} onCancel={disableEditDialog} onConfirm={updateResource} />
			<DeleteResourceDialog visible={showDeleteDialog} resourceName={resourceName} resourceProps={columns} onCancel={disableDeleteDialog} onConfirm={() => deleteResource(selected.id)} />
		</div>

	);
}

export default RestfulTable;
