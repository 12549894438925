import React from 'react';
import { CircularProgress } from 'react-md';

import './customOverlaySpinner.css';

const CustomOverlaySpinner = ({ isLoading }) => {
  if (!isLoading) return null;
  return isLoading && (
    <div className="custom-overlay">
      <div className="custom-overlay-spinner">
        <CircularProgress />
      </div>
      <div className="custom-overlay-background" />
    </div>
  );
};

export default CustomOverlaySpinner;