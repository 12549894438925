export const BINARY = 'binary';
export const UNARY = 'unary';
// Constant query states below are used for the PractitionerDashboard component
export const MAX_PREFETCH_LIMIT = 100; // This is the max of records returned by the API
export const DEFAULT_PAGE_LIMIT = 15;
export const DEFAULT_FILTER_QUERY = [];
export const DEFAULT_SORT_QUERY = { sort: 'title', order: 'asc' };

export const SORT_TO_DATA_MAP = {
  csl: 'csl',
  count: 'count',
  status: 'status',
};

export const FILTER_TO_DATA_MAP = {
  csl: 'csl',
  count: 'count',
  status: 'status',
};

export const OPERATORS_MAP = {
  contains: { key: 'co', type: BINARY },
  equals: { key: 'eq', type: BINARY },
  greaterThan: { key: 'gt', type: BINARY },
  lessThan: { key: 'lt', type: BINARY },
  isBefore: { key: 'ib', type: UNARY },
  isAfter: { key: 'ia', type: UNARY },
};


export const OPERATORS_LIST = Object.keys(OPERATORS_MAP);

export const WELCOME_EMAIL_ACTIVATE_SUCCESS_DIALOG_WARNING_MESSAGE = "The selected CSLs failed to activate."
export const WELCOME_EMAIL_DEACTIVATE_SUCCESS_DIALOG_WARNING_MESSAGE = "The selected CSLs failed to deactivate."
export const WELCOME_EMAIL_SUCCESS_DIALOG_WARNING_MESSAGE = "Please try again. If the problem persists please contact the support team."