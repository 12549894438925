import { useEffect, useState } from "react";
import usePageFramework from "@utilities/hooks/usePageFramework";
import HeaderImage from ".";
import BlankHeaderImage from "./blankHeaderImage";

function HeaderImageRenderer() {
    const { location: { pathname } } = usePageFramework();
    const [isErrorPage, setIsErrorPage] = useState(false);

    useEffect(() => {
        setIsErrorPage(pathname.startsWith('/error/'));
    }, [pathname]);

    return isErrorPage ? <BlankHeaderImage /> : <HeaderImage />;
}

export default HeaderImageRenderer;