import { Button, FontIcon, Collapse as ReactMDCollapse } from 'react-md';
// import { AddSVGIcon, RemoveSVGIcon } from '@react-md/material-icons';
import React, { useEffect, useState, useRef } from 'react';
// import usePageFramework from '@utilities/hooks/usePageFramework';
import usePageFramework from '@utilities/hooks/usePageFramework';

// import { Element } from 'react-scroll';
import BondIDFixedDialog from '../../../dialogBox/bondIdFixedDialog';
import { useToggle } from "@react-md/utils";
import { getClientDetails } from '../../../utils/getClientDetails';
// import { getFormBarcode } from '@utilities/constants/images';
import getDashboard from "@utilities/constants/dashboards";
import getYear from '@utilities/helpers/getYear';
import { getFormTitle } from '../../../utils/getFormTitle';
// import { getFormBarcode } from '@utilities/constants/images';

const useStyles = (
    sectionType,
    title,
    hasLineItemSection,
    isFormExpandable,
    isCollapsed,
    isDenseRow,
    hasOrgs,
    appType,
    filteredGroups,
    filteredGroupItems,
    isEntryInterview,
    bondID
) => {
    const styles = { sectionHeaderTitle: 'sectionHeaderTitle' };

    if (sectionType === 'section') {
        // styles.form = hasLineItemSection === undefined && !isEntryInterview ? 'formSize' : 
        //     hasLineItemSection === undefined && isEntryInterview ? '' : 'lineItemSectionFormSize';
        // if(hasLineItemSection === undefined && isFormExpandable){
        //     styles.form = 'lineItemSectionFormSize';
        // }
        

        const withPadding = hasOrgs &&
            (filteredGroups.length === 0 && filteredGroupItems.length === 0) ? 'withPadding' : '';
        styles.denseRowSectionContainer = title === 'Occupant Details' ? 'noTopPaddingSectionContainer' :
            styles.denseRowSectionContainer = hasOrgs ? `compDetailsdenseRowSectionContainer ${withPadding}` :
                'denseRowSectionContainer';
        styles.helpIcon = 'helpIcon';
        if (isDenseRow) {
            styles.formTitle = bondID ? 'denseRowFormTitleBasicBond' : 'denseRowFormTitle';
            // styles.denseRowBackground = 'denseRowBackground';
        }
        else styles.formTitle = 'formTitle';

        if (isCollapsed) styles.sectionHeaderContainer = appType !== '990' ?
            'denseRowSectionHeaderContainer' : 'denseRowSectionHeaderWithSummaryContainer';
        else styles.sectionHeaderContainer = 'sectionHeaderContainer';
    } else if (sectionType === 'subSection') {
        styles.form = 'formSubSection';
        styles.formTitle = 'formSubSectionTitle';
        styles.helpIcon = 'subSectionhelpIcon';
        styles.sectionHeaderContainer = 'subSectionHeaderContainer';
    }

    if (title.length > 50) {
        styles.sectionHeaderTitle = 'wideSectionHeaderTitle';
        styles.sectionHeaderTotalLabel = 'centeredSectionHeaderTotalLabel';
    } else {
        styles.sectionHeaderTotalLabel = !hasOrgs ? 'sectionHeaderTotalLabel' : 'sectionHeaderTotalsSummary';
    }

    if (title === 'Unreimbursed Business Expenses') { // Check section title if org export style issues occur
        styles.formBodyMargin = 'formBodyMargin';
    }

    return styles;
};

const FormBody = ({
    title,
    collapsed,
    collapseClass,
    renderHeaderTotal,
    handleClick,
    denseRowCollapsed,
    renderSections,
    sectionType,
    marginBottom,
    hasLineItemSection,
    width,
    isFormExpandable,
    setDenseRowCollapsed,
    lastDenseRowSection,
    isDenseRow,
    isFormNA,
    hasOrgs,
    groups,
    denseRowHeaders,
    isSectionHeaderHidden,
    isEntryInterview,
    bondID,
    parentGroup,
    parentIndex,
    hasNoLineItem,
    allSections,
    barcodeName,
    hasPageBreak,
    modifiedFormName,
    parentFormName,
    formName
}) => {
    const { appType, selectState, REDUX } = usePageFramework();
    const isCollapsed = false;
    const isDenseRowCollapsed = false;
    const filteredGroups = groups?.filter(group => group.isVisible);
    let filteredGroupItems = groups?.filter(group => group.groupType === 'groupItem');
    filteredGroupItems = filteredGroupItems
        ?.map(group => group.groupItems
            .map(item => item
                ?.filter(group => group.isVisible))
            .filter(items => items?.length > 0))
        .filter(items => items?.length > 0);

    const classes = useStyles(
        sectionType,
        title,
        hasLineItemSection,
        isFormExpandable,
        isDenseRowCollapsed,
        isDenseRow,
        hasOrgs,
        appType,
        filteredGroups,
        filteredGroupItems,
        isEntryInterview,
        bondID
    );
    const [showDenseRowIcon, setShowDenseRowIcon] = useState(true);
    const [visible, show, hide] = useToggle(false);
    const fixedTo = useRef(null);
    const denseRowBG = isDenseRow && {
        borderRadius: '4px',
        border: 'solid 1px rgba(151, 151, 151, 0.25)',
        margin: 0,
        position: 'relative'
    };
    const margin = denseRowCollapsed ? '0 30px 0 15px' : '-7px 0 0 0';
    const basicBondFlex = bondID ? 'basicBond' : '';
    const isDenseRowHeaderHidden = isDenseRow && allSections?.filter(sec => sec.isVisible).length === 1;
    
    const year = getYear(selectState);
    const { dashboard } = getDashboard(year.current);
    const dashboardCards = dashboard.flatMap(item => item.cards);
    const formTitle = getFormTitle(dashboardCards, modifiedFormName);

    const sectionTitle =
    title === "Farm Equipment and Property (See Next Section for Farm Vehicles)"
      ? "farmEquipment" : title;

    useEffect(() =>
        setTimeout(
            () => setShowDenseRowIcon(denseRowCollapsed), 200),
        [denseRowCollapsed]);

    const renderDenseRowExpandCircleIcon = () => {
        const isTrue = isDenseRow && hasLineItemSection &&
            classes.form === 'lineItemSectionFormSize' &&
            !showDenseRowIcon && !hasNoLineItem;
        return isTrue && <div className="denseRowExpandCircleIcon"></div>;
    };

    const validateTitle = () => {
        const titles = [
            'Business Information',
            'Business Income',
            'Business Expenses',
            'Vehicle Expenses',
            'Business Use of Home',
            'Farm Information',
            'Farm Income',
            'Farm Expenses',
            'Farm Equipment and Property (See Next Section for Farm Vehicles)',
            'Rental Property Information',
            'Rental Property Income',
            'Rental Property Expenses',
            'Travel and Workday History',
            'Separate Home Information',
            'Foreign Taxes',
            'Passthrough Information',
            'Report of Foreign Bank and Financial Accounts',
            'Non-US Directly-Held Investments or Mutual Funds',
            'Ownership in Foreign Entity',
            'Non-US Pension',
            'Non-US Life Insurance Policy Or Trust'
        ];

        // Checking if parent form name is not Passthru Income 
        // to prevent duplicates to Vehicle Expenses sections
        // when generating a PDF
        let pagebreak = titles.includes(title) && !parentFormName?.startsWith(REDUX.PASSTHRU_INCOME) ?
            'pagebreak' : '';

        //To attach the form title and client data above these first sections, cause these first sections will be cut off by page break from above, which makes it lose the form title and client data - so they need to be attached again
        const firstSections = [
            'Business Information',
            'Farm Information',
            'Rental Property Information',
            'Travel and Workday History',
            'Passthrough Information',
            'Report of Foreign Bank and Financial Accounts',
        ];

        let isFirstSection = firstSections.includes(title);

        return { pagebreak, isFirstSection };
    }

    const avoid = () => {
        const titles = [
            'Tell Us',
            'Rental Property Expenses',
            'Business Use of Home',
            'Individual Retirement Accounts (IRA)',
            'Business Information',
            'Business Expenses',
            'Business Income',
            'Taxes Paid',
            'Rental Property Information',
        ];

        return titles.includes(title) ? '' : 'avoid';
    }

    const sectionPadding = () => {
        let padding = '';

        if (formName?.startsWith(REDUX.ENERGY_EFFICIENT) ||
            formName?.startsWith(REDUX.HIGHER_EDUCATION)
        ) {
            padding = 'sectionPadding';
        }

        return padding;
    }

    const renderBondIdDialog = () => {
        let issuerName = '';
        let issuedDate = '';

        if (parentGroup) {
            if (parentGroup?.lineItems?.[parentIndex]) {
                parentGroup.lineItems[parentIndex]?.forEach(item => {
                    if (item.name === 'aboutIssuerSummary') {
                        issuerName = item.issuerName;
                    } else if (item.name === 'issueDetailsSummary') {
                        issuedDate = item.issuedDate;
                    }
                });
            } else if (parentGroup.groupType === 'bondsItem' && parentGroup?.bonds?.[parentIndex]) {
                let bond = parentGroup.bonds[parentIndex];
                if (bond.bondDetails) {
                    issuerName = bond.bondDetails.issuerName;
                    issuedDate = bond.bondDetails.issuedDate;
                }
            }
        }

        return (
            <BondIDFixedDialog
                fixedTo={fixedTo}
                visible={visible}
                hide={hide}
                issuerName={issuerName}
                issuedDate={issuedDate}
            />
        );
    }

    return (

        <div id={sectionTitle} className={`${classes.formBodyMargin} ${validateTitle().pagebreak} ${avoid()}`}>
            {/* {renderDenseRowBtn()} */}
            {modifiedFormName && <p hidden>{modifiedFormName}</p>}
            {
                modifiedFormName && validateTitle().isFirstSection &&
                <div
                    id={`pdfHeaderContainer-${modifiedFormName}`}
                    style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                >
                    <div className="grid-child" style={{ width: 130, height: 31 }}>
                    </div>
                    <div className="grid-child">
                        <center>
                            <h3>{year.current + ' ' + formTitle}</h3>
                        </center>

                    </div>
                    {
                        modifiedFormName &&
                        <div className="grid-child clientDetailsContainer">
                            <span>{getClientDetails().clientName}</span>&nbsp;
                            <span>{getClientDetails().clientNumber}</span>
                        </div>
                    }
                </div>
            }
            <div
                // className={ 
                //     !hasLineItemSection && !isFormExpandable ? width < 1390 && width > 1250 ? 'mediumDesktopFormSize' : 
                //         width < 1250 && width > 1025 ? 'smallDesktopFormSize' : 
                //             width < 1025 ? 'tabletFormSize' : classes.form : classes.form}
                // style={isDenseRow ? {margin, backgroundColor: 'transparent'} : {}}
                style={isDenseRow ? { margin, backgroundColor: 'transparent' } : {}}
            >
                {isSectionHeaderHidden || isDenseRowHeaderHidden ? null : <div
                    className={classes.sectionHeaderContainer}
                    onClick={!isFormNA ? handleClick : null}
                    style={isDenseRow ? { padding: 0 } : {}}
                >
                    <div className={classes.sectionHeaderTitle}>
                        <div className='sectionHeaderTitleContainer'>
                            {/* Uncomment this line to test barcode consistency with the forms */}
                            {/* {
                                modifiedFormName && 
                                <div class="grid-child">
                                    <img 
                                        className="imgBarCode" 
                                        alt={`Org Export - ${modifiedFormName} - bar code`} 
                                        title={`Org Export - ${modifiedFormName} - bar code`} 
                                        src={getFormBarcode(modifiedFormName)} 
                                    />
                                </div>
                            }  */}
                            <div>
                                <p className={classes.formTitle}>{title}</p>
                            </div>
                            {
                                !denseRowCollapsed && bondID &&
                                <div className={basicBondFlex}>
                                    <Button
                                        id="text-button-1"
                                        theme="clear"
                                        className={classes.formTitle}
                                        ref={fixedTo}
                                        onClick={show}
                                    >
                                        <span
                                            style={visible ? { color: '#7dd2d3' } : { color: 'inherit' }}
                                        >
                                            {bondID}
                                        </span>
                                    </Button>
                                    {renderBondIdDialog()}
                                </div>
                            }
                            <div>
                                <FontIcon className={classes.helpIcon}>help</FontIcon>
                            </div>
                        </div>
                    </div>
                    <div className={classes.sectionHeaderTotalLabel}>
                        {!isDenseRow && collapsed ? renderHeaderTotal() : denseRowHeaders ? denseRowHeaders() : null}
                    </div>
                    {/* {
                        !isDenseRow && 
                        <div className="expandFormContainer">
                            <Button data-testid="collapsibleBtnId" className="expandFormBtn" onClick={handleClick} disabled={isFormNA}>
                                <TextIconSpacing 
                                    icon={
                                        collapsed ? 
                                            <ExpandMoreSVGIcon /> : 
                                            <ExpandLessSVGIcon />} 
                                    iconAfter
                                >
                                    {collapsed ? 'Expand' : 'Collapse'}
                                </TextIconSpacing>
                            </Button>
                        </div>
                    } */}
                </div>}
                {
                    !isDenseRow ?
                        <ReactMDCollapse
                            collapsed={isCollapsed}
                            className={
                                `${title !== 'Business Information' ? collapseClass : ''} ${sectionPadding()}`
                            }
                        >
                            <div style={marginBottom}>
                                {renderSections()}
                            </div>
                        </ReactMDCollapse> :
                        <ReactMDCollapse collapsed={isCollapsed} className={classes.denseRowBackground}>
                            <div style={denseRowBG}>
                                <div className={classes.denseRowSectionContainer}>
                                    {renderSections()}
                                </div>
                            </div>
                        </ReactMDCollapse>
                }
                {lastDenseRowSection && lastDenseRowSection.title === title && renderDenseRowExpandCircleIcon()}
            </div>
        </div>
    );
};

export default FormBody;